import { cn, useWindowSize } from '@navi-app/utils';
import { ParagraphLarge, ParagraphSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';

type TPropsFooter = {
  isUnFixed?: boolean;
};

export default function Footer({ isUnFixed }: TPropsFooter) {
  const year = new Date().getFullYear();
  const { isPhoneSize } = useWindowSize();
  const { t } = useTranslation('auth');
  return (
    <div
      className={cn(
        'flex items-center w-full justify-center',
        isUnFixed ? 'mb-6' : ' fixed bottom-6 -z-10'
      )}
    >
      <img
        src="https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/navi/images/ic-copyright.svg"
        alt="copyright"
        className="w-4 h-4 lg:w-5 lg:h-5"
      />
      {isPhoneSize ? (
        <ParagraphSmall color={'#A6A6A6'} marginLeft={'12px'}>
          {year} {t('company')}
        </ParagraphSmall>
      ) : (
        <ParagraphLarge color={'#A6A6A6'} marginLeft={'12px'}>
          {year} {t('company')}
        </ParagraphLarge>
      )}
    </div>
  );
}
