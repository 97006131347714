import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { TVendorCompanies, TVendorCompany } from '../types';

type TCompanySelectionStore = {
  admin: TVendorCompany;
  customers: TVendorCompany[];
  setValues: (companies: TVendorCompanies) => void;
  resetValues: () => void;
};

const companySelectionStore = create<TCompanySelectionStore>()(
  persist(
    (set) => ({
      admin: {} as TVendorCompany,
      customers: [],
      setValues: (companies) =>
        set(() => ({
          admin: companies.admin,
          customers: companies.customers,
        })),
      resetValues: () =>
        set(() => ({
          admin: {} as TVendorCompany,
          customers: [],
        })),
    }),
    {
      name: 'CompanySelectionStore',
    }
  )
);

export default companySelectionStore;
