import { withErrorBoundary } from '@navi-app/utils';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes as RoutesLib } from 'react-router-dom';
import withAuthCheck from '../commons/components/withAuthCheck';
import config from '../commons/constants/config';
import NotFoundPage from '../pages/404/index';
import ChangePassword from '../pages/change-password';
import CompanySelection from '../pages/company-selection';
import EmailVerification from '../pages/email-verification';
import FailedRegistration from '../pages/failed-registration';
import InvalidUrl from '../pages/invalid-url';
import LocationPermission from '../pages/location-permission';
import Login from '../pages/login';
import Register from '../pages/register';
import RegisterEmailVerification from '../pages/register-email-verification';
import RegisterVendor from '../pages/register-vendor';

function Routes() {
  const isLoggedIn = !!Cookies.get('authToken') && !!Cookies.get('userRoleId');
  const isEligibleAccessAsAdmin =
    !!Cookies.get('eligibleToAccess') &&
    Cookies?.get('eligibleToAccess') === 'true';

  useEffect(() => {
    if (isLoggedIn) {
      if (isEligibleAccessAsAdmin) {
        window.location.href = `${config.MOBI_URL}`;
      } else {
        window.location.href = `${config.MOBI_URL}/vendor/company-selection`;
      }
    }
  }, [isLoggedIn, isEligibleAccessAsAdmin]);

  return (
    <BrowserRouter basename="/auth">
      <RoutesLib>
        <Route path="/" element={<Login />} />
        <Route path="/location-permission" element={<LocationPermission />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/invalid-url" element={<InvalidUrl />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/register/email-verification"
          element={<RegisterEmailVerification />}
        />
        <Route path="/register/vendor" element={<RegisterVendor />} />
        <Route
          path="/register/vendor/failed"
          element={<FailedRegistration />}
        />
        <Route
          path="/vendor/company-selection"
          element={<CompanySelection />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </RoutesLib>
    </BrowserRouter>
  );
}

export default withErrorBoundary(withAuthCheck(Routes), {
  isPage: true,
});
