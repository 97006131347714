import { Toaster } from '@navi-app/ui';
import {
  hasNumber,
  hasSymbol,
  hasUpperCase,
  isLengthValid,
  useForm,
} from '@navi-app/utils';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  forgotChangePassword,
  validateTokenForgotPassword,
} from '../../../api/auth';

type TPropsUseChangePassword = {
  t: TFunction;
};

export default function useChangePassword({ t }: TPropsUseChangePassword) {
  const locations = useLocation();
  const query = new URLSearchParams(locations.search);
  const token = query.get('token');

  const [captchaToken, setCaptchaToken] = useState<undefined | string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerifyCaptcha = useCallback((newCaptchaToken: string) => {
    setCaptchaToken(newCaptchaToken);
  }, []);

  const navigate = useNavigate();
  const recaptchaRef = useRef(null);

  const { mutate: mutateValidateToken } = useMutation(
    validateTokenForgotPassword
  );
  const {
    mutate: mutateForgotChangePassword,
    isLoading: isLoadingForgotChangePassword,
  } = useMutation(forgotChangePassword);

  useEffect(() => {
    mutateValidateToken(token as string, {
      onSuccess: (data) => {
        if (!data?.isValid) {
          navigate('/invalid-url', { replace: true });
        }
      },
      onError: (errorResponse) => {
        const error = errorResponse as AxiosError;
        Toaster.negative(error?.message || 'Failed verify token.');
      },
    });
  }, []);

  const [showPassword, setShowPassword] = useState({
    isShowPasswordFirstInput: false,
    isShowPasswordSecondInput: false,
  });
  const { errors, handleChange, values, handleSubmit, touched } = useForm({
    initialValues: {
      password: '',
      repassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(10, t('change_password_validation.message'))
        .matches(/[A-Z]/, t('change_password_validation.message'))
        .matches(/[0-9]/, t('change_password_validation.message'))
        .matches(/[\W_]/, t('change_password_validation.message'))
        .required(t('error_message.required')),
      repassword: Yup.string()
        .oneOf(
          [Yup.ref('password')],
          t('change_password_validation.password_not_match')
        )
        .required(t('error_message.required')),
    }),
    onSubmit: (values) => {
      mutateForgotChangePassword(
        {
          gCaptchaResponseToken: captchaToken || '',
          newPassword: values.password,
          token: token || '',
        },
        {
          onSuccess: () => {
            Toaster.show(t('success_change_password'));
            navigate('/', { replace: true });
            setRefreshReCaptcha((prevState) => !prevState);
            setTimeout(() => {
              navigate(0);
            }, 300);
          },
          onError: (errorResponse) => {
            const error = errorResponse as AxiosError;
            Toaster.negative(error?.message || 'Failed change password.');
          },
        }
      );
    },
  });

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isCanCheckRecaptcha =
      values.password !== '' &&
      values.repassword !== '' &&
      !errors?.password &&
      !errors?.repassword;

    if (isCanCheckRecaptcha) {
      if (!captchaToken) {
        Toaster.negative(t('recaptcha_verify'));
      } else {
        handleSubmit();
      }
    } else {
      handleSubmit();
    }
  };

  const handleShowPassword = (
    key: 'isShowPasswordFirstInput' | 'isShowPasswordSecondInput'
  ) =>
    setShowPassword((prevState) => ({
      ...prevState,
      [`${key}`]: !prevState[`${key}`],
    }));

  const passwordValid = useMemo(() => {
    return {
      isLengthValid: isLengthValid(values?.password, 10),
      hasUpperCase: hasUpperCase(values?.password),
      hasNumber: hasNumber(values?.password),
      hasSymbol: hasSymbol(values?.password),
    };
  }, [values?.password]);

  return {
    showPassword,
    handleShowPassword,
    recaptchaRef,
    errors,
    handleChange,
    values,
    handleSubmit,
    touched,
    onSubmit,
    passwordValid,
    onVerifyCaptcha,
    refreshReCaptcha,
    isLoadingForgotChangePassword,
  };
}
