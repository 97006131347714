import { Meta } from '@navi-app/ui';
import { Button } from 'baseui/button';
import { DisplayXSmall, ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Footer from '../../commons/components/footer';
import config from '../../commons/constants/config';

export default function InvalidUrl() {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const backToMainPage = () => {
    navigate('/', { replace: true });
  };

  return (
    <main>
      <Meta
        key="meta-invalid-url"
        title={`${t('meta.title')}: Invalid URL`}
        description={t('meta.description')}
        config={config}
      />
      <div className="flex items-center justify-center w-full h-screen">
        <div className="flex items-center justify-between">
          <div className="w-[358px] mr-[100px] ">
            <DisplayXSmall>{t('link_invalid_title')}</DisplayXSmall>
            <ParagraphMedium className="!my-4 !text-[#5E5E5E]">
              {t('link_invalid_message')}
            </ParagraphMedium>
            <Button
              className="!text-base lg:!text-lg"
              type="button"
              onClick={backToMainPage}
            >
              {t('back_to_main_page')}
            </Button>
          </div>
          <div className="flex items-center justify-end">
            <img
              src="https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/navi/illustration/error-404.svg"
              alt="404"
              height={320}
              width={480}
            />
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
