import { withErrorBoundary } from '@navi-app/utils';
import {
  HeadingXSmall,
  ParagraphMedium,
  ParagraphSmall,
  ParagraphXSmall,
} from 'baseui/typography';
import { useTranslation } from 'react-i18next';

import { Button, SIZE } from 'baseui/button';
import { LOCATION_STATUS } from './constant/location-permission';
import useLocationPermission from './hooks/use-location-permission';

import { Meta } from '@navi-app/ui';
import { Plus } from 'baseui/icon';
import { Skeleton } from 'baseui/skeleton';
import Loader from '../../commons/components/loader';
import config from '../../commons/constants/config';

const Location = () => {
  const { t } = useTranslation('location');
  const {
    location,
    getCurrentLocation,
    locationStatus,
    isLocationBlocked,
    isLoadingPage,
    isLoadingLocation,
    isErrorLocation,
    isLoadingSendLocationDriver,
    isErrorSendingLocation,
  } = useLocationPermission({
    t,
  });

  if (isLoadingPage) {
    return <Loader />;
  }

  if (isErrorSendingLocation || isErrorLocation) {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.98285 56.3298L30.8182 6.34847C31.2988 5.45595 32.6033 5.45595 33.1525 6.34847L62.0565 56.3298C62.5371 57.2223 61.9192 58.3208 60.8894 58.3208H3.08134C2.05151 58.3208 1.43361 57.2223 1.98285 56.3298Z"
            fill="#F6BC2F"
          />
          <path
            d="M10.0156 53.0344L31.9854 14.9991L53.9552 53.0344H10.0156Z"
            fill="#03222F"
          />
          <path
            d="M31.9854 43.8342C32.5347 43.8342 33.0153 44.0402 33.4272 44.4521C33.8391 44.864 34.0451 45.3446 34.0451 45.8939C34.0451 46.4431 33.8391 46.9237 33.4272 47.3357C33.0153 47.7476 32.5347 47.9536 31.9854 47.9536C31.4362 47.9536 30.887 47.7476 30.5437 47.3357C30.1317 46.9237 29.9258 46.4431 29.9258 45.8939C29.9258 45.3446 30.1317 44.864 30.5437 44.4521C30.9556 44.0402 31.4362 43.8342 31.9854 43.8342ZM30.4064 42.0492L30.1317 27.2882H33.9078L33.5645 42.0492H30.4064Z"
            fill="white"
          />
        </svg>
        <HeadingXSmall className="mt-4">
          {t('error_feedback_token.message')}
        </HeadingXSmall>
        {isErrorSendingLocation && (
          <ParagraphXSmall className="mt-2">
            {t('error_feedback_token.description')}
          </ParagraphXSmall>
        )}
      </div>
    );
  }

  return (
    <main className="h-screen flex flex-col items-center justify-center px-7">
      <Meta
        key="meta-location-permission"
        title={t('meta.title')}
        description={t('meta.description')}
        config={config}
      />
      <div className="flex items-center justify-center">
        <img src={locationStatus?.image} alt="location-illustration" />
      </div>
      <div className="mt-6 mb-3 flex flex-col items-center text-center">
        <HeadingXSmall>{t(locationStatus?.title)}</HeadingXSmall>
        <ParagraphMedium marginTop={'8px'} color={'#5E5E5E'}>
          {t(locationStatus?.description)}
        </ParagraphMedium>
      </div>
      {location?.allowPermission === LOCATION_STATUS.GRANTED &&
        !isErrorLocation && (
          <div className="mt-3 p-3 border-2 border-solid border-[#E8E8E8] rounded">
            {isLoadingLocation || isLoadingSendLocationDriver ? (
              <Skeleton autoSizeRows rows={2} width="260px" animation />
            ) : (
              <ParagraphSmall className="line-clamp-3">
                <b>{location?.locationName} -</b>
                {location?.locationAddress}
              </ParagraphSmall>
            )}
          </div>
        )}
      {location?.allowPermission === LOCATION_STATUS.DENIED &&
        !isLocationBlocked && (
          <Button
            onClick={getCurrentLocation}
            className="!w-[146]"
            startEnhancer={() => <Plus size={16} />}
            size={SIZE.compact}
          >
            {t('share_location')}
          </Button>
        )}
    </main>
  );
};

export default withErrorBoundary(Location, { isPage: true });
