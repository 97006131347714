import {
  ArrowRightStartOnRectangleIcon,
  BuildingOffice2Icon,
} from '@heroicons/react/24/solid';
import { withErrorBoundary } from '@navi-app/utils';
import { Button, KIND } from 'baseui/button';
import { StyledDivider } from 'baseui/divider';
import { HeadingMedium, ParagraphLarge } from 'baseui/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useCompanySelection from './hooks/use-company-selection';
import { TVendorCompany } from './types';

interface CompanyListProps {
  title: string;
  companies: TVendorCompany[];
  onChooseCompany: (id: string, customerId: string) => void;
}

const CompanyList: React.FC<CompanyListProps> = ({
  title,
  companies,
  onChooseCompany,
}) => (
  <div className="mt-10">
    <ParagraphLarge
      color="#5E5E5E"
      className="!font-semibold tracking-wide mb-6"
    >
      {title}
    </ParagraphLarge>
    <div className="flex flex-col gap-6">
      {companies.map((company) => (
        <div
          key={company.id}
          className="flex items-center gap-4 cursor-pointer"
          onClick={() => onChooseCompany(company.userRoleId, company.id)}
        >
          {company?.companyLogo ? (
            <div className="border-2 border-gray-300 flex items-center justify-center p-1 rounded-full h-10 w-10 overflow-hidden">
              <img
                src={company.companyLogo}
                alt={company.companyName}
                className="object-contain h-full w-full"
              />
            </div>
          ) : (
            <div className="border-2 border-gray-300 flex items-center justify-center p-1 rounded-full h-10 w-10">
              <BuildingOffice2Icon height={18} width={18} />
            </div>
          )}
          <ParagraphLarge className="!font-medium">
            {company.companyName}
          </ParagraphLarge>
        </div>
      ))}
    </div>
  </div>
);

const CompanySelection: React.FC = () => {
  const { t } = useTranslation('company-selection');
  const { admin, customers, onChooseCompany, onLogout } = useCompanySelection();

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex justify-center items-center border-b-2 border-[#E8E8E8]">
        <img
          className="border-bottom"
          src="https://assets.mobitech.id/images/Mobitech-NEW.svg"
          alt="navi"
          loading="lazy"
        />
      </div>
      <div className="flex justify-center mt-[60px]">
        <div className="w-11/12 md:w-9/12 lg:w-[493px] relative">
          <div className="p-10 border border-solid border-[#E8E8E8] rounded-xl mb-6">
            <HeadingMedium className="tracking-wider !text-[32px] !font-semibold">
              {t('choose_company')}
            </HeadingMedium>
            {admin && admin?.id && (
              <>
                <CompanyList
                  title={t('admin')}
                  companies={[admin]}
                  onChooseCompany={onChooseCompany}
                />
                <StyledDivider className="my-10" />
              </>
            )}
            {customers && customers?.length > 0 && (
              <CompanyList
                title={t('customer')}
                companies={customers}
                onChooseCompany={onChooseCompany}
              />
            )}
          </div>
          <Button
            kind={KIND.secondary}
            overrides={{
              Root: {
                style: {
                  width: '109px',
                  position: 'absolute',
                  right: 0,
                },
              },
            }}
            onClick={onLogout}
          >
            <ArrowRightStartOnRectangleIcon
              width={24}
              height={20}
              className="mr-2"
            />
            {t('log_out')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(memo(CompanySelection), { isPage: true });
