import { Toaster } from '@navi-app/ui';
import { getEncryptationAES, useForm, useModalStore } from '@navi-app/utils';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import Cookies from 'js-cookie';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import {
  emailVerificationRegister,
  getLatestVersion,
  getVendorData,
  resendVerificationUsingCredentials,
  verifyCaptcha,
} from '../../../api/auth';
import config from '../../../commons/constants/config';
import { MODAL_NAME } from '../../../commons/constants/modal';
import useRegisterStore from '../../../commons/stores/register.store';
import vendorRegisterStore from '../../register-vendor/store/vendor-register.store';
import { useHoneypot } from './use-honeypot';

type TPropsUseLogin = {
  t: TFunction;
  onSubmitLogin: (values: { email: string; password: string }) => void;
};

export default function useLogin({ t, onSubmitLogin }: TPropsUseLogin) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const tokenVendor = searchParams.get('va');
  const { openModal, modalName } = useModalStore();
  const { onSetMultipleData } = vendorRegisterStore();

  const isOpenModalUpdateVersion = modalName === 'modal-update-version';
  const currentVersion = Cookies?.get('latestVersion') || null;
  useQuery('get-update-version', () => getLatestVersion(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    onSuccess: (data) => {
      if (typeof currentVersion === 'undefined' || currentVersion === null) {
        Cookies.set('latestVersion', data?.latestVersion || 'v1', {
          expires: 30,
        });
      } else if (currentVersion && currentVersion !== data?.latestVersion) {
        openModal('modal-update-version', {
          latestVersion: data?.latestVersion || 'v1',
          isLogin: false,
          domain: config.DOMAIN_URL,
        });
      }
    },
    onError: () => {
      if (typeof currentVersion === 'undefined' || currentVersion === null) {
        Cookies.set('latestVersion', 'v1', {
          expires: 30,
        });
      }
    },
  });

  const [tokenValue, setTokenValue] = useState('');

  const { setFormData, setEmailToken } = useRegisterStore(
    useShallow((state) => ({
      setFormData: state.setFormData,
      setEmailToken: state.setEmailToken,
    }))
  );
  const [captchaToken, setCaptchaToken] = useState<undefined | string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { mutate: mutateResendVerification } = useMutation(
    resendVerificationUsingCredentials
  );

  const onResendVerification = (email: string, password: string) => {
    mutateResendVerification(
      { email, password },
      {
        onSuccess: (data) => {
          setFormData({
            companyLogo: {
              id: '',
              url: '',
            },
            companyName: data?.organizationName,
            email: email,
            password: '',
            name: '',
            passwordConfirmation: '',
            profilePicture: {
              id: '',
              url: '',
            },
          });
          setEmailToken(data?.token);
          navigate('/register/email-verification');
        },
      }
    );
  };

  const onVerifyCaptcha = useCallback((newCaptchaToken: string) => {
    setCaptchaToken(newCaptchaToken);
  }, []);

  const refreshCaptcha = () => {
    setRefreshReCaptcha(true);
    setTimeout(() => {
      setRefreshReCaptcha(false);
    }, 1000);
  };

  const { mutate, isLoading: isLoadingVerifyCaptcha } =
    useMutation(verifyCaptcha);
  const recaptchaRef = useRef(null);
  const { fieldNameHoneypot, isBotSubmission } = useHoneypot();
  const { errors, handleChange, values, handleSubmit, touched } = useForm({
    initialValues: {
      email: '',
      password: '',
      [fieldNameHoneypot]: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('error_message.email'))
        .required(t('error_message.required')),
      password: Yup.string().required(t('error_message.required')),
      honeypot: Yup.string(),
      [fieldNameHoneypot]: Yup.string()
        .test('is-empty', 'Invalid field', (value) => !value)
        .nullable(),
    }),
    onSubmit: (values) => {
      if (isBotSubmission(values)) {
        console.warn('Submission blocked due to bot suspicion.');
        return;
      }
      const encryptAES = getEncryptationAES(config.LOGIN_KEY, config.LOGIN_IV);
      const encryptedEmail = encryptAES.encrypt(values.email);
      const encryptedPassword = encryptAES.encrypt(values.password);
      onSubmitLogin({
        email: encryptedEmail,
        password: encryptedPassword,
      });
      refreshCaptcha();
    },
  });

  useQuery('getExistingVendor', () => getVendorData(tokenVendor || ''), {
    enabled: !!tokenVendor,
    onSuccess(data) {
      const inviteStatuses = ['closed', 'no_access'];
      if (data?.vendorId && inviteStatuses.includes(data?.inviteStatus)) {
        navigate('/register/vendor/failed');
      }
      if (data?.vendorId) {
        openModal('modalWelcomeRegistration');
        onSetMultipleData({
          companyName: data.vendorName,
          name: data.userFullName,
          email: data.userEmail,
        });
        navigate(`/register/vendor?va=${tokenVendor}`);
      } else {
        handleChange({
          target: {
            name: 'email',
            value: data?.userEmail,
          },
        });
      }
    },
    onError(err: AxiosError) {
      Toaster.negative(err?.message);
    },
  });

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isCanCheckRecaptcha =
      values.email !== '' &&
      values.password !== '' &&
      !errors?.email &&
      !errors?.password;

    if (isCanCheckRecaptcha) {
      refreshCaptcha();

      if (config.IS_DEVELOPMENT_ENV) {
        handleSubmit();
      } else {
        if (!captchaToken) {
          Toaster.negative(t('recaptcha_verify'));
        } else {
          mutate(captchaToken || '', {
            onSuccess: (data) => {
              if (data?.isSuccess) {
                handleSubmit();
              } else {
                Toaster.negative(t('failed_verfiy_captcha'));
              }
            },
            onError: (errorResponse) => {
              const error = errorResponse as AxiosError;
              Toaster.negative(error?.message || t('failed_verfiy_captcha'));
              refreshCaptcha();
            },
          });
        }
      }
    } else {
      handleSubmit();
    }
  };

  const openModalForgotPassword = () => {
    openModal(MODAL_NAME.FORGOT_PASSWORD);
  };

  // Handling email register verification
  const { mutate: mutateVerification } = useMutation(emailVerificationRegister);
  useEffect(() => {
    if (tokenValue) {
      mutateVerification(tokenValue, {
        onSuccess: () => {
          Toaster.show(t('verification_email_success'));
        },
        onError: (error) => {
          const errorData = error as {
            response: { data: { message: string } };
          };
          Toaster.negative(
            t(errorData.response.data.message || 'verification_email_failed')
          );
        },
        onSettled: () => {
          navigate('/?type=newUser');
          setTokenValue('');
        },
      });
    }
  }, [tokenValue]);

  useEffect(() => {
    if (token) {
      setTokenValue(token);
    }
  }, [token]);

  return {
    onSubmit,
    handleChange,
    values,
    errors,
    touched,
    fieldNameHoneypot,
    openModalForgotPassword,
    recaptchaRef,
    isLoadingVerifyCaptcha,
    onVerifyCaptcha,
    refreshReCaptcha,
    refreshCaptcha,
    setFormData,
    onResendVerification,
    isOpenModalUpdateVersion,
  };
}
