import { Meta } from '@navi-app/ui';
import { createTheme, engine, initSentry } from '@navi-app/utils';
import { BaseProvider } from 'baseui';
import { ToasterContainer } from 'baseui/toast';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as StyletronProvider } from 'styletron-react';
import config from './commons/constants/config';
import Routes from './routes';

const theme = createTheme(); // you can ovrride by parsing object

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

if (config.IS_PRODUCTION_ENV) {
  initSentry(config.SENTRY_DSN);
}

// Google Analytics
if (!config.IS_DEVELOPMENT_ENV) {
  ReactGA.initialize(config.GA_MEASUREMENT_ID);
}

const securityPolicy = {
  'frame-src': ['https://www.google.com'],
};

function App() {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <GoogleReCaptchaProvider reCaptchaKey={config.RECAPTCHA_SITE_KEY}>
            <Meta
              key="csp-auth"
              securityPolicy={securityPolicy}
              config={config}
            />
            <ToasterContainer
              autoHideDuration={3000}
              overrides={{
                Root: { style: { zIndex: 2 } },
                ToastBody: { style: { width: '360px' } },
                ToastCloseIcon: {
                  style: ({ $theme }) => ({
                    display: 'none',
                  }),
                },
              }}
            >
              <Routes />
            </ToasterContainer>
          </GoogleReCaptchaProvider>
        </QueryClientProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
