import { useModalStore, withErrorBoundary } from '@navi-app/utils';
import { Modal, ModalBody } from 'baseui/modal';
import { HeadingMedium, ParagraphMedium } from 'baseui/typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ModalWelcome = () => {
  const { t } = useTranslation('register');
  const { modalName, closeModal } = useModalStore();

  return (
    <Modal
      isOpen={modalName === 'modalWelcomeRegistration'}
      onClose={closeModal}
      autoFocus={false}
      overrides={{
        Dialog: {
          style: {
            width: '520px',
          },
        },
      }}
    >
      <ModalBody className="flex flex-col place-items-center justify-center text-center px-4 py-10">
        <svg
          width="282"
          height="189"
          viewBox="0 0 282 189"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M255.68 34.09C255.386 35.1476 217.434 163.458 217.434 163.458L215.319 171.389C214.203 174.268 212.029 176.618 209.326 177.851C208.504 178.263 207.564 178.556 206.683 178.733C205.743 178.968 29.9037 177.499 29.61 177.323C27.2012 175.971 25.38 173.856 24.3812 171.271C23.265 168.393 23.2062 165.22 23.97 162.224C24.0875 161.754 24.6162 159.698 25.5562 156.349C25.7325 155.703 25.9675 154.998 26.1437 154.234C26.6725 152.413 27.26 150.298 27.9062 147.948C27.965 147.771 28.0237 147.536 28.0825 147.36C38.54 110.23 65.2712 16.935 68.0325 14.4675C70.5587 12.235 73.555 10.6488 76.845 10.0613C81.075 9.3563 248.571 9.65005 248.571 9.65005C252.86 9.65005 256.268 13.1163 257.678 17.17C259.793 22.81 257.208 28.5676 255.68 34.09Z"
            fill="url(#paint0_linear_8084_25210)"
          />
          <path
            d="M249.159 48.1315H225.835V38.144H240.581V38.7315H226.423V47.544H249.159V48.1315Z"
            fill="white"
          />
          <path
            d="M237.056 36.2639H213.497V26.2764H237.056V36.2639ZM214.085 35.6764H236.469V26.8639H214.085V35.6764Z"
            fill="white"
          />
          <path
            d="M61.2175 143.6H60.63V134.553H38.2463V143.6H37.6588V133.965H61.2175V143.6Z"
            fill="white"
          />
          <path
            d="M36.1312 147.948H27.965C28.0237 147.772 28.0825 147.537 28.1412 147.36H36.1312V147.948Z"
            fill="white"
          />
          <path
            d="M49.1737 147.36V157.348H25.615V156.408C25.7912 155.762 26.0262 155.057 26.2025 154.293V156.76H48.5862V147.948H45.0612V147.36H49.1737Z"
            fill="white"
          />
          <path
            d="M74.2013 157.289H50.5837V147.36H55.5187V147.948H51.1712V156.702H73.6137V147.654H74.2013V157.289Z"
            fill="white"
          />
          <path
            d="M72.2037 131.615H48.5862V121.687H72.145V131.615H72.2037ZM49.1737 131.028H71.5575V122.274H49.1737V131.028Z"
            fill="white"
          />
          <path
            d="M199.574 24.0437C199.574 20.6362 196.813 17.875 193.405 17.875H118.616C115.209 17.875 112.448 20.6362 112.448 24.0437V30.0362H199.574V24.0437Z"
            fill="white"
          />
          <path
            d="M117.559 25.8064C118.597 25.8064 119.439 24.9647 119.439 23.9264C119.439 22.8881 118.597 22.0464 117.559 22.0464C116.52 22.0464 115.679 22.8881 115.679 23.9264C115.679 24.9647 116.52 25.8064 117.559 25.8064Z"
            fill="url(#paint1_linear_8084_25210)"
          />
          <path
            d="M122.788 25.8064C123.826 25.8064 124.668 24.9647 124.668 23.9264C124.668 22.8881 123.826 22.0464 122.788 22.0464C121.749 22.0464 120.908 22.8881 120.908 23.9264C120.908 24.9647 121.749 25.8064 122.788 25.8064Z"
            fill="url(#paint2_linear_8084_25210)"
          />
          <path
            d="M128.075 25.8064C129.113 25.8064 129.955 24.9647 129.955 23.9264C129.955 22.8881 129.113 22.0464 128.075 22.0464C127.037 22.0464 126.195 22.8881 126.195 23.9264C126.195 24.9647 127.037 25.8064 128.075 25.8064Z"
            fill="url(#paint3_linear_8084_25210)"
          />
          <path
            d="M112.447 32.2686V71.7486H163.442C166.85 71.7486 169.611 74.5098 169.611 77.9173V83.9098H112.447V86.1423H169.611V107.234H193.405C196.812 107.234 199.574 104.472 199.574 101.065V32.3273H112.447V32.2686Z"
            fill="white"
          />
          <path
            d="M88.6537 71.6899C85.2462 71.6899 82.485 74.4512 82.485 77.8587V83.8512H112.447V71.6899H88.6537Z"
            fill="white"
          />
          <path
            d="M169.552 77.9173C169.552 74.5098 166.791 71.7485 163.384 71.7485H112.389V83.9098H169.552V77.9173Z"
            fill="url(#paint4_linear_8084_25210)"
          />
          <path
            d="M112.447 101.006V86.0835H82.485V154.821C82.485 158.228 85.2462 160.99 88.6537 160.99H163.384C166.791 160.99 169.552 158.228 169.552 154.821V107.175H118.557C115.209 107.175 112.447 104.413 112.447 101.006Z"
            fill="white"
          />
          <path
            d="M112.447 86.0835V101.006C112.447 104.414 115.209 107.175 118.616 107.175H169.611V86.0835H112.447Z"
            fill="url(#paint5_linear_8084_25210)"
          />
          <path
            d="M87.5963 79.6799C88.6346 79.6799 89.4763 78.8382 89.4763 77.7999C89.4763 76.7616 88.6346 75.9199 87.5963 75.9199C86.558 75.9199 85.7162 76.7616 85.7162 77.7999C85.7162 78.8382 86.558 79.6799 87.5963 79.6799Z"
            fill="url(#paint6_linear_8084_25210)"
          />
          <path
            d="M92.825 79.6799C93.8633 79.6799 94.705 78.8382 94.705 77.7999C94.705 76.7616 93.8633 75.9199 92.825 75.9199C91.7867 75.9199 90.945 76.7616 90.945 77.7999C90.945 78.8382 91.7867 79.6799 92.825 79.6799Z"
            fill="url(#paint7_linear_8084_25210)"
          />
          <path
            d="M98.1125 79.6799C99.1508 79.6799 99.9925 78.8382 99.9925 77.7999C99.9925 76.7616 99.1508 75.9199 98.1125 75.9199C97.0742 75.9199 96.2325 76.7616 96.2325 77.7999C96.2325 78.8382 97.0742 79.6799 98.1125 79.6799Z"
            fill="url(#paint8_linear_8084_25210)"
          />
          <path
            d="M185.415 138.019L185.239 136.902C186.708 136.667 188.118 136.256 189.469 135.61C193.405 133.789 196.46 130.557 197.988 126.504C199.515 122.45 199.339 117.985 197.576 114.049L198.575 113.579C200.514 117.809 200.69 122.567 199.045 126.915C197.4 131.262 194.169 134.729 189.939 136.667C188.47 137.314 186.943 137.784 185.415 138.019Z"
            fill="white"
          />
          <path
            d="M178.659 137.196C181.831 136.08 185.768 134.2 188.176 132.202L186.179 137.372L187.941 142.66C185.591 140.486 181.773 138.43 178.659 137.196Z"
            fill="white"
          />
          <path
            d="M83.4837 64.2286C81.545 59.9986 81.3687 55.2398 83.0137 50.8923C84.6587 46.5448 87.89 43.0786 92.12 41.1398C93.5887 40.4936 95.0575 40.0236 96.6437 39.7886L96.82 40.9048C95.3512 41.1398 93.9413 41.5511 92.59 42.1973C88.6537 44.0186 85.5987 47.2498 84.0712 51.3036C82.5437 55.3573 82.72 59.8223 84.4825 63.7586L83.4837 64.2286Z"
            fill="white"
          />
          <path
            d="M103.341 40.6112C100.169 41.7275 96.2325 43.6075 93.8237 45.605L95.8212 40.435L94.0587 35.1475C96.4087 37.3212 100.227 39.3775 103.341 40.6112Z"
            fill="white"
          />
          <path
            d="M137.24 161.049L136.653 166.16C136.653 166.16 134.42 166.747 132.481 165.455V160.402C132.481 160.402 136.418 159.991 137.24 161.049Z"
            fill="#F5BBA4"
          />
          <path
            d="M130.836 82.4414L131.776 133.73L131.894 160.696L137.945 161.049C137.945 161.049 142.175 136.668 140.001 129.676C143.115 117.691 144.936 101.183 145.524 83.0877L130.836 82.4414Z"
            fill="#576C74"
          />
          <path
            d="M161.739 161.049L161.974 166.277C161.974 166.277 159.213 167.511 157.274 166.277L156.98 160.402C156.98 160.402 160.916 159.991 161.739 161.049Z"
            fill="#F5BBA4"
          />
          <path
            d="M131.424 85.3788C131.424 85.3788 136.006 88.7275 143.938 95.6013L150.694 131.263L156.275 160.873L161.739 161.108C161.739 161.108 160.858 139.664 159.389 127.268L156.51 82.8525L131.424 85.3788Z"
            fill="#ABB5BA"
          />
          <path
            d="M165.734 172.74H154.571C154.571 172.74 154.454 172.27 154.454 171.565C154.454 171.154 154.454 170.625 154.571 170.155C154.865 168.804 156.04 169.097 157.039 166.277C157.274 165.69 157.744 165.279 158.155 164.867C159.212 163.81 160.799 163.81 161.797 164.867C163.442 166.512 163.854 168.627 164.852 169.45C165.499 170.037 165.734 170.86 165.792 171.506C165.851 172.211 165.734 172.74 165.734 172.74Z"
            fill="#08509A"
          />
          <path
            d="M140.53 172.74H129.367C129.367 172.74 129.25 172.27 129.25 171.565C129.25 171.154 129.25 170.625 129.367 170.155C129.661 168.804 130.836 169.097 131.835 166.277C132.07 165.69 132.54 165.279 132.951 164.867C134.009 163.81 135.595 163.81 136.594 164.867C138.239 166.512 138.65 168.627 139.649 169.45C140.295 170.037 140.53 170.86 140.589 171.506C140.589 172.211 140.53 172.74 140.53 172.74Z"
            fill="#08509A"
          />
          <path
            d="M165.734 172.74H154.571C154.571 172.74 154.454 172.27 154.454 171.565H165.851C165.851 172.211 165.734 172.74 165.734 172.74Z"
            fill="#04284D"
          />
          <path
            d="M140.53 172.74H129.367C129.367 172.74 129.25 172.27 129.25 171.565H140.647C140.589 172.211 140.53 172.74 140.53 172.74Z"
            fill="#04284D"
          />
          <path
            d="M130.66 84.9088C131.306 87.9051 157.685 95.1901 157.685 88.9626C157.685 84.6739 156.392 68.9876 156.157 62.2313C156.157 61.4676 156.216 60.5276 156.334 59.4701C156.334 59.4701 156.334 59.4701 156.334 59.4114C156.334 59.3526 156.334 59.2351 156.392 59.1763V59.1176C156.451 58.8238 156.451 58.5301 156.51 58.1776V58.1188V57.9426C156.569 57.3551 156.686 56.7676 156.804 56.1801C156.862 55.8276 156.921 55.5338 156.98 55.1813C157.097 54.6526 157.156 54.1238 157.274 53.5364C157.333 53.2426 157.391 52.9488 157.45 52.5963C158.449 46.9563 159.448 41.0813 158.155 39.9063C155.805 37.7913 148.344 34.9126 148.344 34.9126L141.587 35.0301C141.587 35.0301 132.892 36.9688 131.894 39.2013C131.189 40.7876 131.424 47.0151 131.6 52.9488C131.6 53.3013 131.6 53.7126 131.659 54.0651C131.717 54.9463 131.717 55.7688 131.717 56.5913C131.717 56.7088 131.717 56.8851 131.717 57.0026C131.717 57.2376 131.717 57.4726 131.717 57.6488C131.717 58.4713 131.776 59.2351 131.776 59.9401C131.776 59.9988 131.776 59.9988 131.776 60.0576C131.776 61.1738 131.776 62.4076 131.717 63.7001C131.717 64.1113 131.659 64.4638 131.659 64.8751C131.13 74.0401 129.779 81.0313 130.66 84.9088Z"
            fill="#03222F"
          />
          <path
            d="M134.772 67.1665C134.772 67.2252 134.772 67.2252 134.772 67.1665C136.476 71.044 140.824 73.5115 144.172 74.9215C147.815 76.449 151.046 77.0952 151.105 77.0952C151.281 77.154 151.399 77.0365 151.457 76.8602C151.516 76.684 151.399 76.5665 151.222 76.5077C151.164 76.5077 147.991 75.8615 144.407 74.3927C141.117 73.0415 136.946 70.6327 135.36 66.9902C135.301 66.8727 135.125 66.7552 135.007 66.814C134.772 66.9315 134.714 67.049 134.772 67.1665Z"
            fill="#2D4752"
          />
          <path
            d="M142.057 38.2613C143.174 38.9076 144.407 39.2601 145.817 39.4363C147.697 39.6126 149.049 39.3188 149.108 39.3188C149.225 39.2601 149.342 39.1426 149.342 39.0251C149.284 38.9076 149.166 38.7901 149.049 38.7901C148.99 38.7901 143.585 39.9063 140.295 36.0876C140.177 35.9701 140.001 35.9701 139.942 36.0288C139.825 36.1463 139.825 36.3226 139.884 36.3813C140.471 37.1451 141.235 37.7913 142.057 38.2613Z"
            fill="#2D4752"
          />
          <path
            d="M138.591 61.761C139.355 64.9923 141.294 67.8123 142.939 69.6923C145.23 72.336 147.462 73.981 147.521 74.0398C147.639 74.0985 147.815 74.0985 147.874 73.981C147.932 73.8635 147.932 73.6873 147.815 73.6285C147.815 73.6285 145.582 71.9835 143.35 69.3985C141.294 66.9898 138.885 63.2298 138.767 58.9998C138.767 58.8235 138.65 58.706 138.474 58.7648C138.297 58.7648 138.18 58.8823 138.239 59.0585C138.239 59.9398 138.415 60.8798 138.591 61.761Z"
            fill="#2D4752"
          />
          <path
            d="M173.724 48.8952C172.842 48.4839 171.902 48.0139 170.904 47.5439C170.199 47.1914 169.494 46.7802 168.789 46.4277C166.85 45.4289 164.911 44.3127 163.09 43.1377C160.329 42.8439 156.157 42.6677 154.336 43.8427C154.395 44.9589 154.924 46.0752 155.922 46.7214C157.45 47.7202 159.036 48.7777 160.622 49.7764C162.209 50.7752 163.854 51.7739 165.499 52.7139C165.616 52.7727 165.792 52.8902 165.91 52.9489C167.437 53.8302 169.082 54.6527 170.727 55.3577C172.549 56.1802 174.429 56.8852 176.367 57.4727C177.014 57.6489 177.66 57.6489 178.247 57.4727H178.306H178.365L178.482 57.4139L178.659 57.3552C178.776 57.2964 178.894 57.2964 178.952 57.2377C179.187 57.1789 179.364 57.0614 179.54 57.0027C179.951 56.8264 180.304 56.6502 180.715 56.4739C181.479 56.1214 182.184 55.7689 182.889 55.3577C184.299 54.5939 185.65 53.7714 187.001 52.8902C187.647 52.4789 188.294 52.0089 188.94 51.5389C189.586 51.0689 190.232 50.5989 190.82 50.1289C192.054 49.1889 193.346 48.2489 194.404 47.1327C197.752 43.7252 195.872 43.4902 194.991 42.4914C194.169 41.5514 192.465 42.6677 191.466 43.3139C190.291 44.1364 189.058 44.9002 187.824 45.6052C187.178 45.9577 186.59 46.3102 185.944 46.6627C185.297 47.0152 184.71 47.3089 184.064 47.6614C182.771 48.3077 181.479 48.8364 180.186 49.3652C179.54 49.6002 178.894 49.8352 178.247 50.0114C178.071 50.0702 177.895 50.1289 177.719 50.1877C177.66 50.1877 177.601 50.2464 177.484 50.2464C177.366 50.1877 177.307 50.1877 177.19 50.1289C176.074 49.8352 174.899 49.4239 173.724 48.8952Z"
            fill="#F5BBA4"
          />
          <path
            d="M176.779 53.3603C176.896 53.3603 177.014 53.2428 177.014 53.1253C177.072 52.1853 177.484 50.8928 177.719 50.3053C177.66 50.3053 177.601 50.3641 177.484 50.3641C177.366 50.3053 177.307 50.3053 177.19 50.2466C176.955 50.8928 176.544 52.1266 176.485 53.0666C176.544 53.2428 176.661 53.3603 176.779 53.3603Z"
            fill="#E06C5C"
          />
          <path
            d="M177.543 50.9514C177.543 50.8927 177.543 50.8927 177.484 50.8339C177.425 50.7164 177.249 50.6577 177.131 50.7164C176.485 51.0689 175.428 52.1852 175.428 52.2439C175.31 52.3614 175.31 52.4789 175.428 52.5964C175.545 52.7139 175.663 52.7139 175.78 52.5964C175.78 52.5964 176.838 51.4802 177.366 51.1864C177.484 51.1277 177.543 51.0689 177.543 50.9514Z"
            fill="#E06C5C"
          />
          <path
            d="M117.147 64.9337C117.617 62.9362 118.264 61.0562 119.027 59.1762C119.674 57.4724 120.437 55.8274 121.26 54.2412C121.319 54.1237 121.377 53.9474 121.495 53.8299C122.376 52.1262 123.316 50.4224 124.256 48.8362C125.196 47.1912 126.136 45.6049 127.076 44.0187C127.722 42.9612 128.78 42.3737 129.896 42.3149C131.189 44.0774 131.13 48.2487 130.954 51.0099C129.837 52.8899 128.839 54.8874 127.957 56.8262C127.605 57.5312 127.311 58.2362 126.959 58.9412C126.489 59.8812 126.077 60.8799 125.725 61.7612C125.255 62.9362 124.844 64.1699 124.491 65.3449C124.432 65.4624 124.432 65.5212 124.432 65.6387C124.432 65.6974 124.491 65.7562 124.491 65.8737C124.55 66.0499 124.609 66.2262 124.667 66.4024C124.902 67.0487 125.137 67.6949 125.431 68.2824C125.96 69.5749 126.606 70.8087 127.252 72.0424C127.605 72.6887 127.957 73.2762 128.31 73.9224C128.662 74.5099 129.074 75.1562 129.426 75.7437C129.779 76.2724 130.131 76.8012 130.484 77.3299V83.0874C129.661 83.2637 128.839 83.0287 128.192 82.3824C127.135 81.2662 126.077 80.0912 125.079 78.9162C124.55 78.3287 124.08 77.7412 123.61 77.0949C123.14 76.4487 122.67 75.8612 122.2 75.2149C121.26 73.9224 120.379 72.6299 119.556 71.2199C119.145 70.5149 118.734 69.8099 118.381 69.1049C118.205 68.7524 118.029 68.3412 117.852 67.9887C117.735 67.8124 117.676 67.5774 117.559 67.4012C117.5 67.2837 117.5 67.1662 117.441 67.1074L117.382 66.9312L117.324 66.8137V66.7549V66.6962C117.03 66.2262 117.03 65.5799 117.147 64.9337Z"
            fill="#F5BBA4"
          />
          <path
            d="M128.192 59.2937L131.541 53.0662C131.541 53.0662 132.716 50.4812 133.127 49.0712C134.126 46.0162 133.891 45.0174 133.245 41.9624C133.127 41.4924 133.95 37.8499 133.48 37.7324C129.602 39.7887 121.554 50.8337 120.907 52.7137C120.907 52.7137 123.257 57.5899 128.192 59.2937Z"
            fill="#03222F"
          />
          <path
            d="M121.26 65.1102C121.26 65.2277 121.377 65.3452 121.495 65.3452C122.435 65.3452 123.786 65.7564 124.374 65.9327C124.374 65.8739 124.315 65.8152 124.315 65.6977C124.315 65.5802 124.374 65.5214 124.374 65.4039C123.727 65.1689 122.435 64.8164 121.495 64.8164C121.377 64.8164 121.26 64.9339 121.26 65.1102Z"
            fill="#E06C5C"
          />
          <path
            d="M123.727 65.6973C123.786 65.6973 123.786 65.6973 123.845 65.6386C123.962 65.5798 124.021 65.4036 123.904 65.2861C123.492 64.6986 122.376 63.6998 122.317 63.6411C122.2 63.5236 122.082 63.5823 121.965 63.6411C121.847 63.7586 121.906 63.8761 121.965 63.9936C121.965 63.9936 123.081 64.9923 123.434 65.5211C123.551 65.6386 123.61 65.6973 123.727 65.6973Z"
            fill="#E06C5C"
          />
          <path
            d="M131.424 82.6173C131.424 82.6173 133.774 82.8523 134.067 83.616C134.361 84.4385 131.071 84.321 131.071 84.321C131.071 84.321 132.657 84.6148 132.775 85.026C133.069 85.966 130.719 85.7898 129.661 85.496C128.604 85.2023 129.015 84.2623 129.426 84.2623C129.426 84.2623 128.898 83.146 129.603 82.4998C129.603 82.4998 128.898 81.736 129.72 80.7373C129.72 80.7373 129.015 79.3273 130.014 79.0335C130.014 79.0335 129.955 77.1535 130.66 77.2123V78.9748C131.365 78.9748 133.421 79.0335 134.126 79.2685C134.949 79.5035 134.831 80.8548 131.541 80.7373C131.541 80.7373 134.303 80.9135 134.89 81.736C135.477 82.441 133.186 82.6173 131.424 82.6173Z"
            fill="#F5BBA4"
          />
          <path
            d="M131.6 52.9486C131.6 53.3011 131.6 53.7123 131.659 54.0648C131.894 53.6536 132.129 53.2423 132.423 52.8311C132.54 52.5961 132.716 52.3611 132.893 52.1261C133.48 51.2448 134.126 50.3636 134.714 49.6586C134.831 49.5411 134.831 49.3648 134.714 49.2473C134.596 49.1298 134.42 49.1298 134.303 49.2473C133.245 50.3048 132.305 51.7148 131.6 52.9486Z"
            fill="#2D4752"
          />
          <path
            d="M129.955 78.9751C129.955 78.9751 129.309 79.0926 129.368 79.6801C129.426 80.2676 129.603 80.6788 129.603 80.6788C129.603 80.6788 129.133 80.9726 129.191 81.5601C129.25 82.0888 129.485 82.4413 129.485 82.4413C129.485 82.4413 129.191 82.6763 129.133 83.0876C129.133 83.5576 129.191 83.7926 129.191 83.7926"
            stroke="#D45D5D"
            stroke-width="0.334"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M152.456 36.6748L171.021 45.8986C171.021 45.8986 167.379 51.0686 166.732 53.1248C166.086 55.1811 166.145 55.1811 166.145 55.1811L152.456 47.7198V36.6748Z"
            fill="#03222F"
          />
          <path
            d="M204.92 38.2024C204.861 37.9086 204.274 37.7324 204.215 38.0261C204.215 38.0261 204.391 37.3799 203.921 37.3799C203.451 37.4386 203.334 37.7911 202.864 38.3199C202.394 38.8486 200.866 40.1999 200.866 40.1999L198.869 40.9049L198.399 42.3149L202.511 41.4924C202.511 41.4924 203.686 40.7874 204.274 39.8474C204.568 39.3774 204.92 38.7899 204.979 38.2611C204.92 38.2024 204.92 38.2024 204.92 38.2024Z"
            fill="#D45D5D"
          />
          <path
            d="M192.23 44.2536C192.23 44.2536 192.994 41.0223 194.697 40.6698C196.401 40.3173 198.751 40.3761 199.632 39.9648C200.572 39.4948 201.101 38.8486 201.395 39.2011C201.689 39.5536 201.277 39.8473 200.749 40.1998C200.22 40.6111 199.221 41.4923 199.221 41.4923C199.221 41.4923 200.807 41.4336 201.512 41.1398C202.217 40.8461 203.862 38.9073 204.215 38.6723C204.567 38.4373 205.566 38.2611 205.272 38.9073C204.979 39.5536 202.805 42.1386 202.1 42.6086C201.395 43.0786 199.397 43.9011 198.281 44.2536C197.165 44.6061 196.049 44.8998 196.049 44.8998L192.23 44.2536Z"
            fill="#F5BBA4"
          />
          <path
            d="M157.097 45.135C157.332 46.075 157.45 47.1325 157.509 48.19C157.509 48.4838 157.567 48.7775 157.567 49.0125C157.626 49.6 157.626 50.1288 157.626 50.5988C157.802 50.3638 158.037 51.0688 158.214 50.8338C158.155 49.3063 157.979 46.4863 157.685 44.9588C157.626 44.7825 157.509 44.665 157.332 44.7238C157.156 44.7825 157.097 44.9588 157.097 45.135Z"
            fill="#2D4752"
          />
          <path
            d="M146.758 58.3539L140.883 36.0877C140.765 35.6764 140.883 35.2064 141.118 34.8539C141.411 34.5014 141.823 34.3252 142.234 34.3252L148.814 34.5602C149.225 34.5602 149.578 34.7364 149.813 35.0889C150.048 35.3827 150.165 35.7939 150.106 36.2052L146.758 58.3539ZM142.234 35.3827C142.116 35.3827 141.999 35.4414 141.999 35.5002C141.94 35.5589 141.881 35.6764 141.94 35.7939L146.523 53.1252L149.166 35.9702C149.166 35.8527 149.108 35.7352 149.108 35.7352C149.049 35.6764 148.99 35.6177 148.873 35.6177L142.234 35.3827Z"
            fill="#9FA1A4"
          />
          <path
            d="M149.049 56.6501V62.3489C149.049 62.8189 148.696 63.1714 148.226 63.1714H144.819C144.349 63.1714 143.996 62.8189 143.996 62.3489V56.6501C143.996 56.1801 144.349 55.8276 144.819 55.8276H148.226C148.579 55.8276 148.873 56.0626 148.99 56.3564C149.049 56.4739 149.049 56.5914 149.049 56.6501Z"
            fill="#EDEDEE"
          />
          <path
            d="M147.815 55.4751H145.289V56.2976H147.815V55.4751Z"
            fill="#005BA2"
          />
          <path
            d="M149.049 56.415L144.114 61.35V59.94L148.168 55.8862H148.285C148.638 55.8862 148.931 56.0625 149.049 56.415Z"
            fill="#9FA1A4"
          />
          <path
            d="M149.049 57.0615V59.999L145.876 63.1715H144.877C144.407 63.1715 144.055 62.819 144.055 62.349V62.0553L149.049 57.0615Z"
            fill="#9FA1A4"
          />
          <path
            d="M139.355 24.2787C139.355 24.2787 137.71 23.6324 137.299 25.5712C136.888 27.5099 138.944 29.5662 140.589 28.9199C140.53 28.9199 140.178 25.1012 139.355 24.2787Z"
            fill="#F5BBA4"
          />
          <path
            d="M140.119 28.1561C140.001 28.1561 139.943 28.0974 139.884 27.9799C139.707 27.0986 139.237 26.3349 138.532 25.8061C138.415 25.7474 138.415 25.6299 138.474 25.5124C138.532 25.3949 138.65 25.3949 138.767 25.4536C139.59 26.0411 140.119 26.9224 140.295 27.9211C140.354 28.0386 140.295 28.1561 140.119 28.1561C140.177 28.1561 140.177 28.1561 140.119 28.1561Z"
            fill="#D45D5D"
          />
          <path
            d="M138.474 27.0986C138.356 27.0986 138.239 26.9811 138.239 26.8636C138.239 26.8048 138.298 26.6873 138.356 26.6873C138.768 26.4523 139.179 26.3936 139.649 26.3936C139.766 26.3936 139.825 26.5111 139.825 26.6286C139.825 26.7461 139.708 26.8048 139.59 26.8048C139.238 26.8048 138.885 26.8636 138.591 27.0398C138.533 27.0986 138.474 27.0986 138.474 27.0986Z"
            fill="#D45D5D"
          />
          <path
            d="M150.988 24.2787C150.988 24.2787 152.632 23.6324 153.044 25.5712C153.455 27.5099 151.399 29.5662 149.754 28.9199C149.813 28.9199 150.165 25.1012 150.988 24.2787Z"
            fill="#F5BBA4"
          />
          <path
            d="M150.224 28.1561C150.341 28.1561 150.4 28.0974 150.459 27.9799C150.635 27.0986 151.105 26.3349 151.81 25.8061C151.927 25.7474 151.927 25.6299 151.869 25.5124C151.81 25.3949 151.692 25.3949 151.575 25.4536C150.752 26.0411 150.224 26.9224 150.047 27.9211C149.989 28.0386 150.047 28.1561 150.224 28.1561C150.165 28.1561 150.165 28.1561 150.224 28.1561Z"
            fill="#D45D5D"
          />
          <path
            d="M151.869 27.0986C151.928 27.0986 152.045 27.0398 152.045 26.9811C152.104 26.8636 152.045 26.7461 151.986 26.6873C151.575 26.4523 151.164 26.3936 150.694 26.3936C150.576 26.3936 150.518 26.5111 150.518 26.6286C150.518 26.7461 150.635 26.8048 150.753 26.8048C151.105 26.8048 151.458 26.8636 151.751 27.0398C151.81 27.0986 151.869 27.0986 151.869 27.0986Z"
            fill="#D45D5D"
          />
          <path
            d="M149.049 36.029C146.993 37.7327 143.996 37.7327 141.881 36.029L141.94 35.089L142.351 30.2715H148.403L148.696 33.0915L149.049 36.029Z"
            fill="#F5BBA4"
          />
          <path
            d="M148.755 33.0327C148.05 33.914 146.111 35.6177 141.999 35.089L142.41 30.2715H148.461L148.755 33.0327Z"
            fill="#D45D5D"
          />
          <path
            d="M151.281 24.6901C151.222 25.1601 151.105 25.6888 150.987 26.2763C150.87 26.9813 150.635 27.6276 150.459 28.3326C149.46 31.5638 147.639 34.5013 144.701 33.9138C142.293 33.4438 140.824 30.9176 139.943 28.2738C139.708 27.5688 139.531 26.9226 139.355 26.2176C139.238 25.8063 139.179 25.3951 139.12 24.9838C138.944 23.9851 138.826 23.1626 138.768 22.5163C138.709 21.7526 139.003 21.0476 139.649 20.5776C144.525 17.1701 150.988 19.4026 151.399 21.3413C151.516 21.8701 151.457 23.1626 151.281 24.6901Z"
            fill="#F5BBA4"
          />
          <path
            d="M146.64 23.2213C146.64 23.2213 146.934 22.8101 147.756 22.7513C148.638 22.6926 149.754 22.5163 149.695 23.5151L146.64 23.2213Z"
            fill="#010709"
          />
          <path
            d="M144.29 23.2213C144.29 23.2213 143.996 22.8101 143.174 22.7513C142.351 22.6926 141.235 22.5163 141.235 23.5151L144.29 23.2213Z"
            fill="#010709"
          />
          <path
            d="M151.927 20.8712C151.869 22.81 150.987 26.6875 150.694 26.5112C150.4 26.335 150.694 23.9262 150.694 23.1625L150.459 21.165L150.811 20.4012C149.166 19.4025 147.227 18.9912 145.347 19.1087C141.999 19.285 140.06 20.46 140.06 20.46L140.471 21.2237C140.412 21.8112 139.884 24.22 139.884 24.22C139.825 24.5137 139.707 26.4525 139.531 26.3937C139.179 26.2762 138.709 22.6925 138.474 22.105C138.297 21.5762 138.239 21.0475 138.297 20.46C138.356 19.05 139.12 17.17 141.999 15.8187C145.524 14.2325 151.34 15.2312 151.869 20.225C151.927 20.46 151.927 20.6362 151.927 20.8712Z"
            fill="#010709"
          />
          <path
            d="M151.928 20.8713L150.459 21.2238L150.811 20.46C149.166 19.4613 147.227 19.05 145.347 19.1675C141.999 19.3438 140.06 20.5188 140.06 20.5188L140.471 21.2825L138.297 20.5188C138.062 19.2263 138.885 16.8175 141.822 15.525C145.347 13.9388 151.516 14.8788 151.928 20.3425C151.928 20.46 151.928 20.6363 151.928 20.8713Z"
            fill="#010709"
          />
          <path
            d="M143.35 29.5073C143.35 29.5073 145.935 29.9186 147.639 29.5073C147.639 29.5073 147.11 31.2698 145.465 31.2698C143.996 31.2698 143.35 29.5073 143.35 29.5073Z"
            fill="#F4F5F5"
          />
          <path
            d="M149.401 24.984C149.401 25.3952 148.755 25.7477 147.932 25.7477C147.11 25.7477 146.464 25.3952 146.464 24.984C146.464 24.5727 147.11 24.2202 147.932 24.2202C148.755 24.2202 149.401 24.514 149.401 24.984Z"
            fill="white"
          />
          <path
            d="M138.239 19.285C138.239 19.285 136.652 16.1125 139.414 14.7025C142.175 13.2925 146.111 12.8225 147.756 13.5275C148.696 13.88 149.46 14.585 149.989 15.4663C149.989 15.4663 151.751 14.8788 152.456 16.23C153.161 17.5813 151.399 23.3975 151.399 23.3975C151.399 23.3975 152.28 19.4025 149.636 17.4638C146.992 15.525 143.702 14.9963 141.235 16.465C139.825 17.2288 138.944 18.6975 138.826 20.3425L138.239 19.285Z"
            fill="#010709"
          />
          <path
            d="M145.054 25.395L144.349 27.9213C144.29 28.0975 144.407 28.2738 144.642 28.3325H144.701H146.288"
            stroke="#D45D5D"
            stroke-width="0.5"
            stroke-miterlimit="10"
          />
          <path
            d="M147.345 24.8077C147.345 25.0427 147.58 25.219 147.933 25.219C148.285 25.219 148.52 25.0427 148.52 24.8077C148.52 24.5727 148.285 24.3965 147.933 24.3965C147.639 24.3965 147.345 24.5727 147.345 24.8077Z"
            fill="#010709"
          />
          <path
            d="M144.114 24.984C144.114 25.3952 143.468 25.7477 142.645 25.7477C141.823 25.7477 141.176 25.3952 141.176 24.984C141.176 24.5727 141.823 24.2202 142.645 24.2202C143.468 24.2202 144.114 24.514 144.114 24.984Z"
            fill="white"
          />
          <path
            d="M142.116 24.8077C142.116 25.0427 142.351 25.219 142.704 25.219C143.056 25.219 143.291 25.0427 143.291 24.8077C143.291 24.5727 143.056 24.3965 142.704 24.3965C142.351 24.3965 142.116 24.5727 142.116 24.8077Z"
            fill="#010709"
          />
          <path
            d="M206.8 83.9099C201.571 84.7324 200.925 85.3786 200.103 90.6074C199.28 85.3786 198.634 84.7324 193.405 83.9099C198.634 83.0874 199.28 82.4412 200.103 77.2124C200.925 82.4412 201.571 83.0874 206.8 83.9099Z"
            fill="#FFAB00"
          />
          <path
            d="M86.1275 111.405C82.8375 111.934 82.4262 112.345 81.8975 115.635C81.3687 112.345 80.9575 111.934 77.6675 111.405C80.9575 110.877 81.3687 110.465 81.8975 107.175C82.4262 110.524 82.8375 110.935 86.1275 111.405Z"
            fill="#FFAB00"
          />
          <path
            d="M213.791 71.9839C210.501 72.5127 210.09 72.9239 209.561 76.2139C209.032 72.9239 208.621 72.5127 205.331 71.9839C208.621 71.4552 209.032 71.0439 209.561 67.7539C210.09 71.0439 210.501 71.4552 213.791 71.9839Z"
            fill="#FFAB00"
          />
          <defs>
            <linearGradient
              id="paint0_linear_8084_25210"
              x1="38.4755"
              y1="231.62"
              x2="163.026"
              y2="64.7561"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_8084_25210"
              x1="115.675"
              y1="23.9536"
              x2="119.449"
              y2="23.9536"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_8084_25210"
              x1="120.925"
              y1="23.9536"
              x2="124.7"
              y2="23.9536"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_8084_25210"
              x1="126.176"
              y1="23.9536"
              x2="129.95"
              y2="23.9536"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_8084_25210"
              x1="118.665"
              y1="63.7595"
              x2="135.703"
              y2="75.5967"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_8084_25210"
              x1="156.103"
              y1="85.4812"
              x2="127.228"
              y2="106.824"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_8084_25210"
              x1="85.7078"
              y1="77.7995"
              x2="89.4822"
              y2="77.7995"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_8084_25210"
              x1="90.9582"
              y1="77.7995"
              x2="94.7326"
              y2="77.7995"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_8084_25210"
              x1="96.2084"
              y1="77.7995"
              x2="99.9828"
              y2="77.7995"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F6F8" />
              <stop offset="0.54" stop-color="#F1F3F5" />
              <stop offset="0.96" stop-color="#E7EAEC" />
              <stop offset="1" stop-color="#E6E9EB" />
            </linearGradient>
          </defs>
        </svg>

        <HeadingMedium className="mt-3">
          {t('register_vendor.modal_title')}
        </HeadingMedium>
        <ParagraphMedium>{t('register_vendor.modal_desc')}</ParagraphMedium>
      </ModalBody>
    </Modal>
  );
};

export default withErrorBoundary(memo(ModalWelcome));
