import { withErrorBoundary } from '@navi-app/utils';
import { Spinner } from 'baseui/spinner';
import { memo } from 'react';

function Loader() {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <Spinner className="!w-16 !h-16 !border-solid !border-[6px]" />
    </div>
  );
}

export default withErrorBoundary(memo(Loader));
