/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

type TVendorRegisterStore = {
  companyName: string;
  name: string;
  email: string;
  onSetMultipleData: (data: any) => void;
  resetData: () => void;
};

const defaultData = {
  companyName: '',
  name: '',
  email: '',
};

const vendorRegisterStore = create<TVendorRegisterStore>((set) => ({
  companyName: '',
  name: '',
  email: '',
  onSetMultipleData: (data) => set((oldState) => ({ ...oldState, ...data })),
  resetData: () => set(defaultData),
}));

export default vendorRegisterStore;
