import { Error404 } from '@navi-app/ui';
import { withErrorBoundary } from '@navi-app/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function NotFoundPage() {
  useTranslation('error');
  const navigate = useNavigate();

  return (
    <Error404
      onClick={() => {
        navigate('/', { replace: true });
      }}
    />
  );
}

export default withErrorBoundary(NotFoundPage);
