const {
  VITE_ENVIRONMENT,
  VITE_API_URL,
  VITE_MOBI_URL,
  VITE_OPTIMA_URL,
  VITE_DOMAIN_APP,
  VITE_CSP_ALLOWED_URL,
  VITE_CSP_ALLOWED_SUB_URL,
  VITE_NOMINATIM_API_URL,
  VITE_RECAPTCHA_SITE_KEY,
  VITE_SENTRY_DSN,
  VITE_AUTH_URL,
  VITE_GA_MEASUREMENT_ID,
  VITE_BRAND_URL,
  VITE_LOGIN_KEY,
  VITE_LOGIN_IV,
} = import.meta.env;

const config = {
  ENV: VITE_ENVIRONMENT,
  API_URL: VITE_API_URL,
  APP_NAME: 'auth',
  IS_DEVELOPMENT_ENV: VITE_ENVIRONMENT === 'development',
  IS_PRODUCTION_ENV: VITE_ENVIRONMENT === 'production',
  MOBI_URL: VITE_MOBI_URL,
  OPTIMA_URL: VITE_OPTIMA_URL,
  DOMAIN_URL: VITE_DOMAIN_APP,
  CSP_ALLOWED_URL: VITE_CSP_ALLOWED_URL,
  CSP_ALLOWED_SUB_URL: VITE_CSP_ALLOWED_SUB_URL,
  NOMINATIM_API_URL: VITE_NOMINATIM_API_URL,
  RECAPTCHA_SITE_KEY: VITE_RECAPTCHA_SITE_KEY,
  SENTRY_DSN: VITE_SENTRY_DSN,
  AUTH_URL: VITE_AUTH_URL,
  GA_MEASUREMENT_ID: VITE_GA_MEASUREMENT_ID,
  BRAND_URL: VITE_BRAND_URL,
  LOGIN_KEY: VITE_LOGIN_KEY,
  LOGIN_IV: VITE_LOGIN_IV,
};
export default config;
