export const CONTENT_LOCATION_STATUS = {
  SUCCESS: {
    image:
      'https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/navi/illustration/accept-and-do-the-job.svg',
    title: 'title.success',
    description: 'description.success',
  },
  PENDING: {
    image:
      'https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/navi/illustration/enable-gps.svg',
    title: 'title.pending',
    description: 'description.pending',
  },
  BLOCKED: {
    image:
      'https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/navi/illustration/data-access.svg',
    title: 'title.block',
    description: 'description.block',
  },
};

export const LOCATION_STATUS = {
  GRANTED: 'granted',
  DENIED: 'denied',
  ERROR: 'error',
  UNKNOWN: 'unknown',
};
