import { useModalStore } from '@navi-app/utils';
import { useNavigate } from 'react-router-dom';
import { MODAL_NAME } from '../../../commons/constants/modal';

export default function useEmailVerification() {
  const { openModal } = useModalStore();
  const navigate = useNavigate();

  const handleUseAnotherEmail = () => {
    navigate('/', { replace: true });
    setTimeout(() => {
      openModal(MODAL_NAME.FORGOT_PASSWORD);
    }, 300);
  };

  return {
    handleUseAnotherEmail,
  };
}
