import { cleanEmptyKeyObject, httpRequest } from '@navi-app/utils';
import config from '../commons/constants/config';
import {
  TParamsForgotChangePassword,
  TParamsLogin,
  TParamsRegister,
  TParamsRegisterVendor,
} from '../commons/types/auth.type';

export const login = async (payload: TParamsLogin) => {
  try {
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/login`,
      payload,
      {
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const verifyCaptcha = async (captchaToken: string) => {
  try {
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/validate-captcha`,
      {
        gCaptchaResponseToken: captchaToken,
      },
      {
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const sendEmailForgotPassword = async (email: string) => {
  try {
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/forgot-password`,
      {
        email,
      },
      {
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const forgotChangePassword = async (
  params: TParamsForgotChangePassword
) => {
  try {
    const response = await httpRequest.fetchApi.put(
      `${config.API_URL}/api/auth/forgot-password/change-password`,
      {
        newPassword: params.newPassword,
        gCaptchaResponseToken: params.gCaptchaResponseToken,
      },
      {
        headers: {
          'X-Api-Key': params.token,
        },
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const validateTokenForgotPassword = async (token: string) => {
  try {
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/forgot-password/validate`,
      null,
      {
        headers: {
          'X-Api-Key': token,
        },
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const register = async (payload: TParamsRegister) => {
  try {
    const cleanPayload = cleanEmptyKeyObject(payload);
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/register`,
      cleanPayload,
      {
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const resendEmailVerificationRegister = async (token: string) => {
  try {
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/re-send-verification-account`,
      null,
      {
        headers: {
          'X-Api-Key': token,
        },
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const resendVerificationUsingCredentials = async ({
  email,
  password,
}: TParamsLogin) => {
  try {
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/re-send-verification-account/`,
      { email, password },
      {
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const emailVerificationRegister = async (token: string) => {
  try {
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/verification`,
      null,
      {
        headers: {
          'X-Api-Key': token,
        },
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const registerVendor = async (payload: TParamsRegisterVendor) => {
  try {
    const cleanPayload = cleanEmptyKeyObject(payload);
    const response = await httpRequest.fetchApi.post(
      `${config.API_URL}/api/auth/register/vendor-account`,
      cleanPayload,
      {
        withRequestId: true,
        headers: {
          'X-Api-Key': payload.token,
        },
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const getVendorData = async (token: string) => {
  try {
    const response = await httpRequest.fetchApi(
      `${config.API_URL}/api/vendor/invitation`,
      {
        headers: {
          'X-Api-Key': token,
        },
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

export const getLatestVersion = async () => {
  try {
    const response = await httpRequest.fetchApi(`/api/shared/app-version`);
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return (
      data?.data || {
        latestVersion: null,
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};
