import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import config from '../constants/config';

const withAuthCheck = (WrappedComponent: React.ComponentType) => {
  const AuthCheck: React.FC = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get('authToken'));
    const [isEligibleToAccess, setIsEligibleToAccess] = useState(
      Cookies.get('eligibleToAccess') === 'true'
    );

    useEffect(() => {
      const checkCookies = () => {
        const authToken = !!Cookies.get('authToken');
        const eligibleToAccess = Cookies.get('eligibleToAccess') === 'true';

        if (authToken !== isLoggedIn) {
          setIsLoggedIn(authToken);
        }

        if (eligibleToAccess !== isEligibleToAccess) {
          setIsEligibleToAccess(eligibleToAccess);
        }

        if (authToken && eligibleToAccess) {
          window.location.href = `${config.MOBI_URL}`;
        }
      };

      if (!isLoggedIn || !isEligibleToAccess) {
        const intervalId = setInterval(checkCookies, 1000);

        return () => clearInterval(intervalId);
      }
    }, [isLoggedIn, isEligibleToAccess]);

    return <WrappedComponent {...props} />;
  };

  return AuthCheck;
};

export default withAuthCheck;
