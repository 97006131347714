import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { withErrorBoundary } from '@navi-app/utils';

type TPropsFieldErrorMessage = {
  errorMessage: string;
};

function FieldErrorMessage({ errorMessage }: TPropsFieldErrorMessage) {
  return (
    <div className="flex items-center">
      <InformationCircleIcon className="w-[14px] h-[14px] mr-1 mb-[1px]" />
      <span>{errorMessage}</span>
    </div>
  );
}

export default withErrorBoundary(FieldErrorMessage);
