import { Toaster } from '@navi-app/ui';
import { useForm } from '@navi-app/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import { getVendorData, register } from '../../../api/auth';
import useRegisterStore from '../../../commons/stores/register.store';

export default function useRegister() {
  const { t } = useTranslation('register');
  const { token } = useParams<{ token: string }>();

  const navigate = useNavigate();
  const { setFormData, resetFormData, setEmailToken } = useRegisterStore(
    useShallow((state) => ({
      setFormData: state.setFormData,
      resetFormData: state.resetFormData,
      setEmailToken: state.setEmailToken,
    }))
  );

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowPasswordConfirmation, setIsShowPasswordConfirmation] =
    useState(false);
  const handShowPassword = () => setIsShowPassword(!isShowPassword);
  const handShowPasswordConfirmation = () =>
    setIsShowPasswordConfirmation(!isShowPasswordConfirmation);

  useQuery('getVendorDataByToken', () => getVendorData(token || ''), {
    enabled: !!token,
    onSuccess: (data) => {},
  });

  const initialValues = {
    companyName: '',
    name: '',
    countryCode: '+62',
    phoneNumber: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    isChecked: false,
  };

  const { mutate: mutateRegister, isLoading } = useMutation(register);

  // Default reset store
  useEffect(() => {
    resetFormData();
  }, [resetFormData]);

  const { handleSubmit, values, touched, errors, handleChange, setValues } =
    useForm({
      initialValues,
      validationSchema: Yup.object({
        companyName: Yup.string().required(t('error_message.required')),
        name: Yup.string().required(t('error_message.required')),
        countryCode: Yup.string().required(t('error_message.required')),
        phoneNumber: Yup.string().required(t('error_message.required')),
        email: Yup.string()
          .email(t('error_message.email'))
          .required(t('error_message.required')),
        password: Yup.string()
          .min(10, t('password_validation.minimumChar'))
          .matches(/[A-Z]/, t('password_validation.minimumCapitalChar'))
          .matches(/[0-9]/, t('password_validation.minimumNumber'))
          .matches(/[\W_]/, t('password_validation.minimumSymbol'))
          .required(t('error_message.required')),
        passwordConfirmation: Yup.string()
          .required(t('error_message.required'))
          .oneOf(
            [Yup.ref('password'), ''],
            t('error_message.password_not_match')
          ),
      }),
      onSubmit: (data) => {
        setFormData(data);
        const payload = {
          companyName: data.companyName,
          userFullName: data.name,
          countryCode: data.countryCode,
          phoneNumber: data.phoneNumber,
          email: data.email,
          password: data.password,
        };
        mutateRegister(payload, {
          onSuccess: (data) => {
            Promise.resolve(setEmailToken(data?.reSendEmailToken || '')).then(
              () => {
                navigate(`/register/email-verification`);
              }
            );
          },
          onError: (error) => {
            const errorData = error as {
              response: { data: { message: string } };
            };
            Toaster.negative(
              t(errorData.response.data.message || 'ERR_INVALID_REQUEST')
            );
          },
        });
      },
    });

  const changeValue = (key: string, value: unknown) => {
    return setValues({
      ...values,
      [key]: value,
    });
  };

  return {
    t,
    isShowPassword,
    handShowPassword,
    handleSubmit,
    handShowPasswordConfirmation,
    isShowPasswordConfirmation,
    values,
    touched,
    errors,
    handleChange,
    changeValue,
    isLoading,
  };
}
