import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Meta, ModalUpdateVersion } from '@navi-app/ui';
import { cn, withErrorBoundary } from '@navi-app/utils';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { StyledLink } from 'baseui/link';
import { colors } from 'baseui/tokens';
import {
  HeadingLarge,
  LabelLarge,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { memo } from 'react';
import ReactGA from 'react-ga4';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import Footer from '../../commons/components/footer';
import ModalForgotPassword from '../../commons/components/modal-forgot-password';
import config from '../../commons/constants/config';
import useAuth from '../../commons/hooks/use-auth';
import useLogin from './hooks/use-login';

const Login = () => {
  const { t } = useTranslation(['auth', 'common']);
  const {
    isErrorAuthenticated,
    onSubmitLogin,
    setIsErrorAuthenticated,
    isLoading,
    handShowPassword,
    isShowPassword,
    showErrorVerification,
    isErrorRateLimit,
  } = useAuth();
  const {
    errors,
    handleChange,
    onSubmit,
    touched,
    values,
    fieldNameHoneypot,
    openModalForgotPassword,
    isLoadingVerifyCaptcha,
    refreshReCaptcha,
    onVerifyCaptcha,
    onResendVerification,
    isOpenModalUpdateVersion,
  } = useLogin({
    t,
    onSubmitLogin,
  });

  if (!config.IS_DEVELOPMENT_ENV) {
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname || '/auth',
      title: `${t('meta.title')}: Login`,
    });
  }

  return (
    <>
      <Meta
        key="meta-login"
        title={`${t('meta.title')}: Login`}
        description={t('meta.description')}
        config={config}
      />
      <main>
        <div className="flex flex-col items-center justify-between lg:justify-center h-screen px-4 pt-[44px] lg:pt-0 pb-[30px] lg:pb-0">
          <div className="px-4 py-5 lg:p-10 border border-solid borde-[#E8E8E8] rounded-lg w-full lg:w-[493px]">
            <img
              src="https://assets.mobitech.id/images/mobitech-logo.webp"
              alt="navi-logo"
              height={58}
              width={206}
            />

            <HeadingLarge marginTop="40px" marginBottom="24px">
              {t('title')}
            </HeadingLarge>

            {showErrorVerification ? (
              <div className="flex gap-4 p-4 bg-[#FFF0EE] rounded-xl place-items-center mb-6">
                <ExclamationCircleIcon width={32} height={32} />
                <div>
                  <ParagraphMedium className="!font-medium">
                    {t('warning_verified.title')}
                  </ParagraphMedium>
                  <ParagraphMedium>
                    {`${t('warning_verified.desc')} `}
                    <a
                      href="/auth/register/email-verification"
                      className="!text-[#1E64DC]"
                      onClick={(e) => {
                        e.preventDefault();
                        onResendVerification(values.email, values.password);
                      }}
                    >
                      {t('warning_verified.resend')}
                    </a>
                  </ParagraphMedium>
                </div>
              </div>
            ) : null}

            <form onSubmit={onSubmit}>
              <input
                type="text"
                name={fieldNameHoneypot}
                value={values[fieldNameHoneypot]}
                onChange={handleChange}
                style={{
                  position: 'absolute',
                  top: '-9999px',
                  left: '-9999px',
                  opacity: 0,
                }}
                tabIndex={-1}
                autoComplete="off"
                aria-hidden="true"
              />
              <FormControl
                label={<LabelLarge>{t('email.title')}</LabelLarge>}
                overrides={{
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      marginBottom: '24px',
                    }),
                  },
                }}
                error={
                  touched?.email && errors?.email ? (
                    <div className="flex items-center">
                      <InformationCircleIcon className="w-[14px] h-[14px] mr-1" />
                      <span>{errors?.email}</span>
                    </div>
                  ) : null
                }
              >
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t('email.placeholder')}
                  value={values?.email}
                  onChange={(event) => {
                    handleChange(event);
                    setIsErrorAuthenticated(false);
                  }}
                  error={
                    (touched?.email && errors?.email) || isErrorAuthenticated
                  }
                />
              </FormControl>
              <FormControl
                label={<LabelLarge>{t('password.title')}</LabelLarge>}
                error={
                  touched?.password && errors?.password ? (
                    <div className="flex items-center">
                      <InformationCircleIcon className="w-[14px] h-[14px] mr-1" />
                      <span>{errors?.password}</span>
                    </div>
                  ) : null
                }
              >
                <Input
                  id="password"
                  name="password"
                  type={isShowPassword ? 'text' : 'password'}
                  placeholder={t('password.placeholder')}
                  value={values?.password}
                  onChange={(event) => {
                    handleChange(event);
                    setIsErrorAuthenticated(false);
                  }}
                  error={
                    (touched?.password && errors?.password) ||
                    isErrorAuthenticated
                  }
                  overrides={{
                    MaskToggleButton: {
                      style: ({ $theme }) => ({
                        display: 'none',
                      }),
                    },
                  }}
                  endEnhancer={() =>
                    !isShowPassword ? (
                      <EyeSlashIcon
                        className="cursor-pointer h-5 w-5"
                        onClick={handShowPassword}
                      />
                    ) : (
                      <EyeIcon
                        className="cursor-pointer h-5 w-5"
                        onClick={handShowPassword}
                      />
                    )
                  }
                />
              </FormControl>
              <StyledLink
                className="!no-underline text-[#1E64DC] cursor-pointer"
                onClick={openModalForgotPassword}
              >
                {t('forgot_password')}?
              </StyledLink>

              <GoogleReCaptcha
                onVerify={onVerifyCaptcha}
                refreshReCaptcha={refreshReCaptcha}
              />

              <Button
                className="!w-full !text-base lg:!text-lg !mt-6 lg:!mt-8"
                isLoading={isLoading || isLoadingVerifyCaptcha}
                type="submit"
              >
                {t('title')}
              </Button>

              {!errors?.email && !errors?.password && isErrorAuthenticated && (
                <div
                  className={cn(
                    'flex mt-6 text-[#E11900]',
                    !isErrorRateLimit && 'items-center'
                  )}
                >
                  <InformationCircleIcon
                    className={cn(
                      isErrorRateLimit
                        ? 'w-[18px] h-[18px] mr-2 mt-0.5'
                        : 'w-[14px] h-[14px] mr-1'
                    )}
                  />
                  <ParagraphSmall color={colors.red400}>
                    {isErrorRateLimit ? (
                      <span>
                        {t('error_message.rate_limit')}
                        <a
                          href="mailto:care@mobitech.id"
                          className="underline text-blue-500"
                          title="Email customer care"
                        >
                          care@mobitech.id
                        </a>
                      </span>
                    ) : (
                      t('error_message.general')
                    )}
                  </ParagraphSmall>
                </div>
              )}
            </form>

            <div className="flex mt-6 lg:mt-8 items-center">
              <ParagraphMedium>
                {t('dont_have_account')}?
                <StyledLink
                  className="!no-underline text-[#1E64DC] cursor-pointer ml-1"
                  href="/auth/register"
                >
                  {t('register_now')}
                </StyledLink>
              </ParagraphMedium>
            </div>
          </div>
        </div>
        <Footer isUnFixed />
        <ModalForgotPassword />
        {/* Modal Update Version */}
        {isOpenModalUpdateVersion && <ModalUpdateVersion />}
      </main>
    </>
  );
};

export default withErrorBoundary(memo(Login), { isPage: true });
