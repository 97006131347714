import { withErrorBoundary } from '@navi-app/utils';
import { Button } from 'baseui/button';
import { HeadingLarge, ParagraphMedium } from 'baseui/typography';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import config from '../../commons/constants/config';

const FailedRegistration = () => {
  const { t } = useTranslation('register');

  const phone = '+6281113607111';
  const email = 'care@mobitech.id';

  const sanitizedDesc = DOMPurify.sanitize(t('failed.desc', { phone, email }));

  const redirectToLandingPage = () => {
    window.location.href = `${config.BRAND_URL}`;
  };

  return (
    <div className="w-screen h-screen flex flex-col">
      <div className="py-4 flex justify-center border-b-2">
        <img
          src="https://assets.mobitech.id/images/Mobitech-NEW.svg"
          alt="navi"
          loading="lazy"
        />
      </div>
      <div className="flex-grow flex place-items-center justify-center">
        <div className="px-4 py-5 lg:p-10 border border-solid border-[#E8E8E8] rounded-lg w-full md:w-[660px] flex flex-col justify-center place-items-center gap-6">
          <img
            src="https://assets.mobitech.id/icons/error-414.svg"
            alt="navi"
            loading="lazy"
          />
          <div>
            <HeadingLarge className="text-center">
              {t('failed.title', { phone, email })}
            </HeadingLarge>
            <ParagraphMedium
              color="#5E5E5E"
              className="text-center mt-4"
              dangerouslySetInnerHTML={{ __html: sanitizedDesc }}
            />
          </div>
          <Button
            onClick={redirectToLandingPage}
            className="max-w-[256px] mx-auto"
          >
            {t('failed.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(FailedRegistration);
