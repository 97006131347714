import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { EnvelopeOpenIcon } from '@heroicons/react/24/solid';
import { Meta } from '@navi-app/ui';
import { StyledLink } from 'baseui/link';
import { HeadingMedium, ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import Footer from '../../commons/components/footer';
import config from '../../commons/constants/config';
import useEmailVerification from './hooks/use-email-verification';

export default function EmailVerification() {
  const { t } = useTranslation('auth');
  const { handleUseAnotherEmail } = useEmailVerification();

  return (
    <main>
      <Meta
        key="meta-email-verification"
        title={`${t('meta.title')}: Email Verification`}
        description={t('meta.description')}
        config={config}
      />
      <div className="flex items-center justify-center h-screen p-4">
        <div className="p-10 border border-solid border-[#E8E8E8] flex flex-col justify-between items-center rounded-xl w-full lg:w-[493px]">
          <div className="p-[18px] bg-[#F4F6F8] rounded-full relative">
            <EnvelopeOpenIcon className="text-[#03222F] w-[46px] h-[46px]" />
            <div className="bg-white absolute w-5 h-5 right-4 top-6 rounded-full">
              <CheckCircleIcon className="text-[#0E8345] w-5 h-5" />
            </div>
          </div>
          <HeadingMedium className="!mt-4 text-center">
            {t('check_email')}
          </HeadingMedium>
          <ParagraphMedium className="!mt-2 text-center">
            {t('check_email_message')}
          </ParagraphMedium>
          <ParagraphMedium className="!mt-10 !text-[#5E5E5E] text-center">
            {t('recheck_email')}{' '}
            <StyledLink
              className="!no-underline !text-[#1E64DC] cursor-pointer ml-1"
              onClick={handleUseAnotherEmail}
            >
              {t('use_another_email')}
            </StyledLink>
          </ParagraphMedium>
        </div>
      </div>
      <Footer />
    </main>
  );
}
