import { CheckCircleIcon } from '@heroicons/react/16/solid';
import { ParagraphXSmall } from 'baseui/typography';

type TPropsValidationMessage = {
  label: string;
  type: 'default' | 'negative' | 'positive';
};

export default function ValidationMessage({
  label,
  type,
}: TPropsValidationMessage) {
  return (
    <div className="flex items-center min-w-[150px]">
      {type === 'positive' ? (
        <CheckCircleIcon className="w-3 h-3 text-[#0E8345] mr-2" />
      ) : type === 'negative' ? (
        <div className="w-3 h-3 bg-[#DE1135] rounded-full mr-2"></div>
      ) : (
        <div className="w-3 h-3 bg-[#DDDDDD] rounded-full mr-2"></div>
      )}
      <ParagraphXSmall
        color={
          type === 'default'
            ? '#5E5E5E'
            : type === 'negative'
            ? '#DE1135'
            : '#03222F'
        }
      >
        {label}
      </ParagraphXSmall>
    </div>
  );
}
