import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Toaster } from '@navi-app/ui';
import { toaster } from 'baseui/toast';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import {
  getLocationByLatLong,
  sendCurrentDriverLocation,
} from '../../../api/location';
import useLocationStore from '../../../commons/stores/location.store';
import {
  TEventPermissionLocation,
  TLocationState,
  TPermissonStatus,
  TPropsUseLocationPermission,
} from '../../../commons/types/location.type';
import {
  CONTENT_LOCATION_STATUS,
  LOCATION_STATUS,
} from '../constant/location-permission';

type TErrorResponse = {
  response: { data: { error: { message: string }; message?: string } };
};

const defaultStateValue = {
  allowPermission: null,
  latitude: null,
  longitude: null,
  locationAddress: '',
  locationName: '',
};

export default function useLocationPermission({
  t,
}: TPropsUseLocationPermission) {
  const [isLocationBlocked, setIsLocationBlocked] = useLocationStore(
    useShallow((state) => [state.isLocationBlocked, state.setIsLocationBlocked])
  );

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [location, setLocation] = useState<TLocationState>(defaultStateValue);

  const urlLocation = useLocation();
  const searchParams = new URLSearchParams(urlLocation.search);
  const driverLogKey = searchParams.get('driverLogKey') as string;

  const {
    mutateAsync: mutateCurrentDriverLocation,
    isLoading: isLoadingSendLocationDriver,
    isError: isErrorSendingLocation,
  } = useMutation(sendCurrentDriverLocation);

  const getCurrentLocation = () => {
    setIsLoadingPage(true);
    setLocation(defaultStateValue);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation((prevState) => ({
            ...prevState,
            allowPermission: LOCATION_STATUS.GRANTED,
            latitude,
            longitude,
          }));
          setIsLoadingPage(false);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setLocation((prevState) => ({
                ...prevState,
                allowPermission: LOCATION_STATUS.DENIED,
              }));
              break;
            case error.POSITION_UNAVAILABLE:
            case error.TIMEOUT:
              setLocation((prevState) => ({
                ...prevState,
                allowPermission: LOCATION_STATUS.ERROR,
              }));
              break;
            default:
              setLocation((prevState) => ({
                ...prevState,
                allowPermission: LOCATION_STATUS.UNKNOWN,
              }));
              break;
          }
          setIsLoadingPage(false);
        }
      );
    } else {
      Toaster.warning(t('toast_message.warning'));
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const locationStatus = useMemo(() => {
    switch (location.allowPermission) {
      case LOCATION_STATUS.GRANTED:
        return CONTENT_LOCATION_STATUS.SUCCESS;
      case LOCATION_STATUS.DENIED:
        if (isLocationBlocked) {
          return CONTENT_LOCATION_STATUS.BLOCKED;
        }
        return CONTENT_LOCATION_STATUS.PENDING;
      case LOCATION_STATUS.ERROR:
        // Need State or Use Pendng State ?
        return CONTENT_LOCATION_STATUS.PENDING;
      default:
        return CONTENT_LOCATION_STATUS.PENDING;
    }
  }, [location.allowPermission, isLocationBlocked]);

  useEffect(() => {
    const handlePermissionChange = (event: TEventPermissionLocation) => {
      if (event.target.state === LOCATION_STATUS.DENIED) {
        if (document.visibilityState !== 'hidden') {
          setIsLocationBlocked(true);
        } else {
          // Pending
          setIsLocationBlocked(false);
        }
        getCurrentLocation();
      } else {
        setIsLocationBlocked(false);
        getCurrentLocation();
      }
    };

    const permissionStatus = navigator.permissions.query({
      name: 'geolocation',
    });
    permissionStatus.then((status: TPermissonStatus) => {
      status.onchange = handlePermissionChange;
    });

    return () => {
      permissionStatus.then((status) => {
        status.onchange = null;
      });
    };
  }, []);

  const locationPayload = {
    lat: location.latitude || 0,
    lon: location.longitude || 0,
    token: driverLogKey,
  };

  const isCanGetLocationByLatLong = useMemo(() => {
    return (
      location.allowPermission === LOCATION_STATUS.GRANTED &&
      !!location.latitude &&
      !!location.longitude
    );
  }, [location]);

  const generateLocationAddress = (label: string, name: string) => {
    const labels = label.split(',');
    const labelsWithoutName = labels?.filter((item) => item !== name);
    return labelsWithoutName?.join();
  };

  const { isLoading: isLoadingLocation, isError: isErrorLocation } = useQuery(
    ['getLocationByLatLong', locationPayload],
    () => {
      return getLocationByLatLong(locationPayload);
    },
    {
      enabled: isCanGetLocationByLatLong,
      onSuccess: (data) => {
        if (data?.error) {
          toaster.negative(
            <div className="flex items-center text-base text-red-500">
              <XCircleIcon className="text-red-500 mr-6 w-6 h-6" />
              {`${data?.error || ''}`}
            </div>
          );
          return;
        }

        const label = data?.features?.[0]?.properties?.geocoding?.label || '';
        const name =
          data?.features?.[0]?.properties?.geocoding?.name ||
          data?.features?.[0]?.properties?.geocoding?.district ||
          '';
        const locationAddress = generateLocationAddress(label, name);
        setLocation((prevState) => ({
          ...prevState,
          locationName: name,
          locationAddress,
        }));
      },
      onError: (data: TErrorResponse) => {
        toaster.negative(
          <div className="flex items-center text-base text-red-500">
            <XCircleIcon className="text-red-500 mr-6 w-6 h-6" />
            {`${
              data?.response?.data?.message ||
              data?.response?.data?.error?.message ||
              t('error_feedback_token.message')
            }`}
          </div>
        );
      },
      retry: false,
    }
  );

  useEffect(() => {
    if (
      location.latitude &&
      location.longitude &&
      location.locationAddress &&
      driverLogKey
    ) {
      mutateCurrentDriverLocation(
        {
          driverLogKey,
          payload: {
            latitude: location.latitude,
            longitude: location.longitude,
            address: location.locationAddress,
          },
        },
        {
          onSuccess: () => {
            toaster.info(
              <div className="flex items-center text-base">
                <CheckCircleIcon className="text-white mr-6 w-6 h-6" />
                {t('toast_message.success')}
              </div>
            );
          },
          onError: (err) => {
            const error = err as TErrorResponse;
            toaster.negative(
              <div className="flex items-center text-base text-red-500">
                <XCircleIcon className="text-red-500 mr-6 w-6 h-6" />
                {error?.response?.data?.message ||
                  error?.response?.data?.error?.message ||
                  t('toast_message.failed')}
              </div>
            );
          },
        }
      );
    }
  }, [
    location.latitude,
    location.longitude,
    location.locationAddress,
    driverLogKey,
  ]);

  return {
    location,
    getCurrentLocation,
    locationStatus,
    isLocationBlocked,
    isLoadingPage,
    isLoadingLocation,
    isErrorLocation,
    isLoadingSendLocationDriver,
    isErrorSendingLocation,
  };
}
