import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { HeadingLarge, LabelLarge, ParagraphSmall } from 'baseui/typography';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import Footer from '../../commons/components/footer';
import ValidationMessage from './components/validation-message';
import { PASSWORD_VALIDATIONS } from './constants';
import useChangePassword from './hooks/use-change-password';

export default function ChangePassword() {
  const { t } = useTranslation('auth');
  const {
    handleShowPassword,
    showPassword,
    errors,
    handleChange,
    onSubmit,
    touched,
    values,
    passwordValid,
    onVerifyCaptcha,
    refreshReCaptcha,
    isLoadingForgotChangePassword,
  } = useChangePassword({
    t,
  });

  return (
    <>
      <main>
        <div className="flex flex-col items-center justify-between lg:justify-center h-screen px-4 pt-[44px] lg:pt-0 pb-[30px] lg:pb-0">
          <div className="px-4 py-5 lg:p-10 border border-solid borde-[#E8E8E8] rounded-lg w-full lg:w-[493px]">
            <img
              src="https://assets.mobitech.id/images/mobitech-logo.webp"
              alt="navi-logo"
              height={58}
              width={206}
            />
            <HeadingLarge marginTop="40px" marginBottom="24px">
              {t('create_new_password')}
            </HeadingLarge>
            <form onSubmit={onSubmit}>
              <FormControl
                label={<LabelLarge>{t('password.title')}</LabelLarge>}
                error={
                  touched?.password && errors?.password ? (
                    <div className="flex items-center">
                      <InformationCircleIcon className="w-[14px] h-[14px] mr-1" />
                      <span>{errors?.password}</span>
                    </div>
                  ) : null
                }
              >
                <Input
                  id="password"
                  name="password"
                  value={values?.password}
                  onChange={handleChange}
                  type={
                    showPassword.isShowPasswordFirstInput ? 'text' : 'password'
                  }
                  placeholder={t('password.placeholder')}
                  overrides={{
                    MaskToggleButton: {
                      style: ({ $theme }) => ({
                        display: 'none',
                      }),
                    },
                  }}
                  endEnhancer={() =>
                    !showPassword.isShowPasswordFirstInput ? (
                      <EyeSlashIcon
                        className="cursor-pointer h-5 w-5"
                        onClick={() =>
                          handleShowPassword('isShowPasswordFirstInput')
                        }
                      />
                    ) : (
                      <EyeIcon
                        className="cursor-pointer h-5 w-5"
                        onClick={() =>
                          handleShowPassword('isShowPasswordFirstInput')
                        }
                      />
                    )
                  }
                />
              </FormControl>
              <FormControl
                label={<LabelLarge>{t('retype_password')}</LabelLarge>}
                error={
                  touched?.repassword && errors?.repassword ? (
                    <div className="flex items-center">
                      <InformationCircleIcon className="w-[14px] h-[14px] mr-1" />
                      <span>{errors?.repassword}</span>
                    </div>
                  ) : null
                }
              >
                <Input
                  id="repassword"
                  name="repassword"
                  value={values?.repassword}
                  onChange={handleChange}
                  type={
                    showPassword.isShowPasswordSecondInput ? 'text' : 'password'
                  }
                  placeholder={t('password.placeholder')}
                  overrides={{
                    MaskToggleButton: {
                      style: ({ $theme }) => ({
                        display: 'none',
                      }),
                    },
                  }}
                  endEnhancer={() =>
                    !showPassword.isShowPasswordSecondInput ? (
                      <EyeSlashIcon
                        className="cursor-pointer h-5 w-5"
                        onClick={() =>
                          handleShowPassword('isShowPasswordSecondInput')
                        }
                      />
                    ) : (
                      <EyeIcon
                        className="cursor-pointer h-5 w-5"
                        onClick={() =>
                          handleShowPassword('isShowPasswordSecondInput')
                        }
                      />
                    )
                  }
                />
              </FormControl>
              {touched?.password && errors?.password && (
                <ParagraphSmall className="!mt-4" color="#DE1135">
                  {t('change_password_validation.title')}
                </ParagraphSmall>
              )}
              <div className="mt-4 flex flex-col lg:flex-row lg:flex-wrap gap-y-2">
                {PASSWORD_VALIDATIONS.map((validation, index) => {
                  const isValid =
                    (validation.key === 'minimumChar' &&
                      passwordValid.isLengthValid) ||
                    (validation.key === 'minimumNumber' &&
                      passwordValid.hasNumber) ||
                    (validation.key === 'minimumCapitalChar' &&
                      passwordValid.hasUpperCase) ||
                    (validation.key === 'minimumSymbol' &&
                      passwordValid.hasSymbol);
                  if (values?.password === '') {
                    return (
                      <ValidationMessage
                        key={validation.key}
                        label={t(validation.label)}
                        type="default"
                      />
                    );
                  }
                  return (
                    <ValidationMessage
                      key={validation.key}
                      label={t(validation.label)}
                      type={
                        isValid
                          ? 'positive'
                          : !touched?.password
                          ? 'default'
                          : 'negative'
                      }
                    />
                  );
                })}
              </div>
              <GoogleReCaptcha
                onVerify={onVerifyCaptcha}
                refreshReCaptcha={refreshReCaptcha}
              />
              <Button
                className="!w-full !text-base lg:!text-lg !mt-6 lg:!mt-8"
                type="submit"
                isLoading={isLoadingForgotChangePassword}
              >
                {t('create_new_password')}
              </Button>
            </form>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}
