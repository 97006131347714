import { Toaster } from '@navi-app/ui';
import { useForm } from '@navi-app/utils';
import { TFunction } from 'i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { registerVendor } from '../../../api/auth';

const useRegisterVendor = (t: TFunction) => {
  const [searchParams] = useSearchParams();
  const tokenVendor = searchParams.get('va');
  const navigate = useNavigate();
  const { mutate } = useMutation(registerVendor);

  const { values, errors, touched, setValues, handleSubmit } = useForm({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(10, t('password_validation.minimumChar'))
        .matches(/[A-Z]/, t('password_validation.minimumCapitalChar'))
        .matches(/[0-9]/, t('password_validation.minimumNumber'))
        .matches(/[\W_]/, t('password_validation.minimumSymbol'))
        .required(t('error_message.required')),
      passwordConfirmation: Yup.string()
        .required(t('error_message.required'))
        .oneOf(
          [Yup.ref('password'), ''],
          t('error_message.password_not_match')
        ),
    }),
    onSubmit: (values) => {
      onRegisterVendor();
    },
  });

  const onRegisterVendor = async () => {
    mutate(
      { token: tokenVendor || '', password: values?.password || '' },
      {
        onSuccess: () => {
          Toaster.show(t('register_vendor.register_succeeded'));
          navigate('/');
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (err: any) => {
          if (
            err?.response?.data?.errorCode ===
            'ERR_VENDOR_REGISTRATION_CANCELLED'
          ) {
            navigate('/register/vendor/failed');
          } else {
            Toaster.negative(err?.message);
          }
        },
      }
    );
  };

  const changeValue = (key: string, value: string) => {
    setValues(() => ({
      ...values,
      [key]: value,
    }));
  };

  return {
    onRegisterVendor,
    values,
    errors,
    touched,
    changeValue,
    handleSubmit,
  };
};

export default useRegisterVendor;
