/* eslint-disable @typescript-eslint/no-explicit-any */
import { withErrorBoundary } from '@navi-app/utils';
import { Button } from 'baseui/button';
import { HeadingSmall, ParagraphSmall } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import Layout from './layout';
import { THeader } from './layout/header';

type TError404 = {
  onClick: () => void;
  config?: any;
  headerProps?: THeader;
};

function Error404({ onClick, config, headerProps }: TError404) {
  const { t } = useTranslation('error');
  return (
    <Layout
      headerType="detail"
      title=""
      config={config}
      headerProps={headerProps}
      rootClass="bg-[#F4F6F8] h-screen"
      customClass="flex justify-center"
    >
      <div className=" bg-white p-10 w-fit rounded-lg mt-2 shadow-sm">
        <div className="flex justify-center">
          <img
            src="https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/ilustration/error-404.svg"
            alt="404-not-found"
          />
        </div>
        <div className="text-center">
          <HeadingSmall className="mt-6">{t('error_404.title')}</HeadingSmall>
          <ParagraphSmall className="mt-4 mb-6">
            {t('error_404.desc')}
          </ParagraphSmall>
          <Button
            overrides={{ BaseButton: { style: { width: '100%' } } }}
            onClick={onClick}
          >
            {t('error_404.action')}
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default withErrorBoundary(Error404);
