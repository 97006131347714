import { encryptStorage } from '@navi-app/utils';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import config from '../constants/config';

type TFormData = {
  companyName: string;
  companyLogo: { url: string; id: string } | null;
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  profilePicture: { url: string; id: string } | null;
};
type TUseRegisterStore = {
  formData: TFormData;
  setFormData: (newData: TFormData) => void;
  resetFormData: () => void;
  reSendEmailToken: string;
  setEmailToken: (token: string) => void;
};

const initialValues = {
  formData: {
    companyName: '',
    companyLogo: null,
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    profilePicture: null,
  },
  reSendEmailToken: '',
};
const storage = config.IS_DEVELOPMENT_ENV ? localStorage : encryptStorage;
const useRegisterStore = create<TUseRegisterStore>()(
  persist(
    (set, get) => ({
      ...initialValues,
      setFormData: (newData: TFormData) => set({ formData: newData }),
      resetFormData: () => set({ formData: initialValues.formData }),
      setEmailToken: (token: string) => set({ reSendEmailToken: token }),
    }),
    {
      name: '@register-form-data',
      storage: createJSONStorage(() => storage),
    }
  )
);

export default useRegisterStore;
