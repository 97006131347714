import { withErrorBoundary } from '@navi-app/utils';
import { Button } from 'baseui/button';
import { StyledDivider } from 'baseui/divider';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import {
  HeadingLarge,
  ParagraphLarge,
  ParagraphMedium,
  ParagraphXSmall,
} from 'baseui/typography';
import { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import FieldErrorMessage from '../register/components/field-error-message';
import ModalWelcome from './components/modal-welcome';
import useRegisterVendor from './hooks/use-register-vendor';
import vendorRegisterStore from './store/vendor-register.store';

const Footer = lazy(() => import('../../commons/components/footer'));

const RegisterVendorPage = () => {
  const { t } = useTranslation('register');
  const { email, companyName, name } = vendorRegisterStore();
  const { changeValue, errors, touched, handleSubmit } = useRegisterVendor(t);

  return (
    <div className="flex flex-col items-center justify-between lg:justify-center min-h-screen px-4 pt-[44px] lg:pt-0 pb-[30px] lg:pb-0 mt-10">
      <div className="px-4 py-5 lg:p-10 border border-solid border-[#E8E8E8] rounded-lg w-full lg:w-[493px] mb-6">
        <img
          src="https://assets.mobitech.id/images/Mobitech-NEW.svg"
          alt="navi"
          loading="lazy"
        />
        <HeadingLarge marginTop="40px" marginBottom="24px">
          {t('title')}
        </HeadingLarge>
        <ParagraphXSmall color="#5E5E5E">
          {t('form.company.name')}
        </ParagraphXSmall>
        <ParagraphMedium className="font-medium mb-6">
          {companyName}
        </ParagraphMedium>

        <ParagraphXSmall color="#5E5E5E">{t('form.name')}</ParagraphXSmall>
        <ParagraphMedium className="font-medium mb-6">{name}</ParagraphMedium>

        <ParagraphXSmall color="#5E5E5E">{t('form.email')}</ParagraphXSmall>
        <ParagraphMedium className="font-medium mb-6">{email}</ParagraphMedium>
        <StyledDivider className="mb-6" />
        <ParagraphLarge className="mb-2">
          {t('form.password')}
          <span className="text-red-500 text-base -mt-1">*</span>
        </ParagraphLarge>
        <FormControl
          error={
            touched?.password && errors?.password ? (
              <FieldErrorMessage errorMessage={errors?.password} />
            ) : null
          }
        >
          <Input
            type="password"
            placeholder={t('form.password_placeholder')}
            onChange={(event) =>
              changeValue('password', event.currentTarget.value)
            }
          />
        </FormControl>
        <ParagraphLarge className="mb-2 mt-6">
          {t('form.password_confirmation')}
          <span className="text-red-500 text-base -mt-1">*</span>
        </ParagraphLarge>
        <FormControl
          error={
            touched?.passwordConfirmation && errors?.passwordConfirmation ? (
              <FieldErrorMessage errorMessage={errors?.passwordConfirmation} />
            ) : null
          }
        >
          <Input
            type="password"
            placeholder={t('form.password_confirmation_placholder')}
            onChange={(event) =>
              changeValue('passwordConfirmation', event.currentTarget.value)
            }
          />
        </FormControl>
        <Button
          overrides={{
            Root: {
              style: {
                marginTop: '24px',
                width: '100%',
              },
            },
          }}
          onClick={handleSubmit}
        >
          {t('title')}
        </Button>
      </div>
      <Footer isUnFixed />

      <ModalWelcome />
    </div>
  );
};

export default withErrorBoundary(RegisterVendorPage, { isPage: true });
