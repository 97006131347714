import { useEffect, useState } from 'react';

// Note: 2 min threshold for detect bot submission
const THRESHOLD_TIME = 3000;
export const useHoneypot = () => {
  const [fieldName, setFieldName] = useState('');
  const [startTime, setStartTime] = useState<number | null>(null);

  useEffect(() => {
    // Generate a random field name to avoid bot detection
    const randomFieldName = `field_${Math.random().toString(36).substring(7)}`;
    setFieldName(randomFieldName);

    // Record form load time
    setStartTime(Date.now());
  }, []);

  const isBotSubmission = (values: Record<string, string>) => {
    // Check if honeypot field is filled
    if (values[fieldName]) {
      console.warn('Bot detected via honeypot.');
      return true;
    }

    // Check if form submission was too fast
    const elapsedTime = Date.now() - (startTime || 0);
    if (elapsedTime < THRESHOLD_TIME) {
      // Adjust the threshold as needed
      console.warn('Bot detected via time-based check.');
      return true;
    }

    return false;
  };

  return { fieldNameHoneypot: fieldName, isBotSubmission };
};
