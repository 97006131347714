import { Meta } from '@navi-app/ui';
import { withErrorBoundary } from '@navi-app/utils';
import { Image } from '@unpic/react';
import { StyledLink } from 'baseui/link';
import { HeadingXSmall, ParagraphMedium } from 'baseui/typography';
import config from '../../commons/constants/config';
import useVerification from './hooks/use-verification';

function RegisterEmailVerification() {
  const { t, onResendVerification, formData } = useVerification();

  return (
    <>
      <Meta
        key="meta-register-email-verification"
        title={`${t('meta.title')} Email Verification`}
        description={t('meta.description')}
        config={config}
      />
      <div className="w-full h-screen flex flex-col items-center justify-center gap-10">
        <img
          src="https://assets.mobitech.id/images/mobitech-logo.webp"
          alt="mobitech-logo"
          height={58}
          width={206}
        />
        <div className="border border-[#E6E9EB] px-10 py-12 flex flex-col items-center gap-6">
          <HeadingXSmall>{t('verification.title')}</HeadingXSmall>
          <Image
            src="https://assets.mobitech.id/images/illustration-email-sent.svg"
            alt="illustration-email-sent"
            width={240}
            height={240}
          />
          <div className="flex flex-col items-center gap-1">
            <ParagraphMedium color={'#66717C'}>
              {t('verification.gretting', {
                name: formData?.companyName || '-',
              })}
            </ParagraphMedium>
            <ParagraphMedium className="text-center">
              <div
                dangerouslySetInnerHTML={{
                  __html: t('verification.content', {
                    email: formData?.email || '-',
                  }),
                }}
              />
            </ParagraphMedium>
          </div>
          <ParagraphMedium>
            {t('verification.resend_message')}?
            <StyledLink
              className="!no-underline text-[#1E64DC] cursor-pointer ml-1"
              onClick={onResendVerification}
            >
              {t('verification.resend')}
            </StyledLink>
          </ParagraphMedium>
        </div>
      </div>
    </>
  );
}

export default withErrorBoundary(RegisterEmailVerification, { isPage: true });
