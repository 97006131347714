import {
  httpRequest,
  initI18n,
  providerTranslationValue,
} from '@navi-app/utils';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import config from './app/commons/constants/config';

import { I18nextProvider } from 'react-i18next';
import App from './app/app';

httpRequest.initFetch(
  {
    baseURL: config.API_URL,
    timeout: 600000,
    // TODO add custom headers
    headers: {},
  },
  {
    domainApp: config.DOMAIN_URL,
    authUrl: '/',
  }
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
initI18n('/auth');
root.render(
  <StrictMode>
    <I18nextProvider i18n={providerTranslationValue}>
      <App />
    </I18nextProvider>
  </StrictMode>
);
