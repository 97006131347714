// import { encryptStorage } from '@navi-app/utils';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
// import config from '../constants/config';

type TState = {
  isLocationBlocked: boolean;
  setIsLocationBlocked: (locationBlocked: boolean) => void;
};

// TODO: check encryptStorage doesn't set as localStorahe to keep the state
// const storage = config.IS_DEVELOPMENT_ENV ? localStorage : encryptStorage;

const storage = localStorage;
const defaultState = {
  isLocationBlocked: false,
};

const useLocationStore = create<TState>()(
  persist(
    (set, get) => ({
      ...defaultState,
      setIsLocationBlocked: (locationBlocked: boolean) =>
        set({ isLocationBlocked: locationBlocked }),
    }),
    {
      name: '@location-access',
      storage: createJSONStorage(() => storage),
    }
  )
);

export default useLocationStore;
