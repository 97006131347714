import { Toaster } from '@navi-app/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useShallow } from 'zustand/react/shallow';
import { resendEmailVerificationRegister } from '../../../api/auth';
import useRegisterStore from '../../../commons/stores/register.store';

export default function useVerification() {
  const { t } = useTranslation('register');
  const { formData, reSendEmailToken } = useRegisterStore(
    useShallow((state) => ({
      formData: state.formData,
      reSendEmailToken: state.reSendEmailToken,
    }))
  );
  const [isVerified, setIsVerified] = useState(false);

  const { mutate } = useMutation(resendEmailVerificationRegister);

  useEffect(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (isVerified) {
      Toaster.show(t('verification.message_success'));
    }
  }, [isVerified, t]);

  const onResendVerification = () => {
    mutate(reSendEmailToken, {
      onSuccess: () => {
        Toaster.show(t('verification.message_success'));
      },
      onError: (error) => {
        const errorData = error as {
          response: { data: { message: string } };
        };
        Toaster.negative(
          t(errorData.response.data.message || 'ERR_INVALID_REQUEST')
        );
      },
    });
  };

  return {
    t,
    onResendVerification,
    formData,
  };
}
