import { useForm, useModalStore } from '@navi-app/utils';
import { TFunction } from 'i18next';
import { FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { sendEmailForgotPassword } from '../../api/auth';
import { EMAIL_RESPONSE_MESSAGE, MODAL_NAME } from '../constants/modal';

type TPropsUseForgotPassword = {
  t: TFunction;
};

const defaultEmailValidationState = {
  isValid: true,
  message: '',
};

export default function useForgotPassword({ t }: TPropsUseForgotPassword) {
  const { modalName, closeModal } = useModalStore();
  const [validateEmail, setValidateEmail] = useState(
    defaultEmailValidationState
  );

  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(sendEmailForgotPassword);

  const { errors, handleChange, values, handleSubmit, touched, resetForm } =
    useForm({
      initialValues: {
        email: '',
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email(t('error_message.email'))
          .required(t('error_message.required')),
      }),
      onSubmit: (values) => {
        handleSendEmail();
      },
    });

  const handleSendEmail = async () => {
    mutate(values.email, {
      onSuccess: (data) => {
        setValidateEmail({
          isValid: data.message === EMAIL_RESPONSE_MESSAGE.SUCCESS,
          message:
            data.message === EMAIL_RESPONSE_MESSAGE.EMAIL_NOT_REGISTERED
              ? t('email_not_valid')
              : data.message === EMAIL_RESPONSE_MESSAGE.NOT_SUCCESS
              ? t('failed_send_email')
              : '',
        });
        if (data.message === EMAIL_RESPONSE_MESSAGE.SUCCESS) {
          navigate(`/email-verification`, {
            replace: true,
          });
        }
      },
      onError: () => {
        setValidateEmail({
          isValid: false,
          message: t('failed_send_email'),
        });
      },
    });
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit();
  };

  const isOpen = modalName === MODAL_NAME.FORGOT_PASSWORD;
  const isDisabled = values.email === '';

  const handleCloseModal = () => {
    closeModal();
    setValidateEmail(defaultEmailValidationState);
    resetForm();
  };

  return {
    onSubmit,
    errors,
    handleChange,
    values,
    touched,
    isOpen,
    closeModal: handleCloseModal,
    isDisabled,
    validateEmail,
    isLoading,
  };
}
