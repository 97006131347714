import { httpRequest } from '@navi-app/utils';
import Config from '../commons/constants/config';
import {
  TParamsGetLocationByLatLongBy,
  TParamsSendCurrentLocationDriver,
} from '../commons/types/location.type';

export const getLocationByLatLong = async (
  params: TParamsGetLocationByLatLongBy
) => {
  try {
    const response = await httpRequest.fetchApi(
      `${Config.API_URL}/api/shared/nominatim/reverse/token`,
      {
        params: {
          lat: params.lat,
          lon: params.lon,
          format: 'geocodejson',
          'accept-language': 'id',
        },
        headers: {
          'X-Api-Key': params.token,
        },
      }
    );

    if ([200, 201].indexOf(response.status) === -1) {
      throw new Error(response?.data?.message);
    }

    return response?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendCurrentDriverLocation = async ({
  driverLogKey,
  payload,
}: TParamsSendCurrentLocationDriver) => {
  try {
    const response = await httpRequest.fetchApi.post(
      `api/mobi/order/driver/log`,
      payload,
      {
        headers: {
          'X-Api-Key': driverLogKey,
        },
        withRequestId: true,
      }
    );
    const data = response?.data;
    if ([200, 201].indexOf(data.statusCode) === -1) {
      throw new Error(data.message);
    }
    return data?.data || null;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};
