import { Toaster } from '@navi-app/ui';
import {
  INDEXED_DB_CONFIG,
  indexedDBStorage,
  setAuthentication,
} from '@navi-app/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { login } from '../../api/auth';
import companySelectionStore from '../../pages/company-selection/store/company-selection.store';
import { TVendorCompanies } from '../../pages/company-selection/types';
import config from '../constants/config';

const useLogin = () => {
  const { t } = useTranslation('auth');
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(login);
  const { setValues } = companySelectionStore();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isErrorAuthenticated, setIsErrorAuthenticated] = useState(false);
  const [showErrorVerification, setShowErrorVerification] = useState(false);
  const [isErrorRateLimit, setIsErrorRateLimit] = useState(false);

  const { init } = indexedDBStorage({
    isDevelopmentEnv: false,
    storeName: INDEXED_DB_CONFIG.stores.user,
  });

  const onSubmitLogin = (values: { email: string; password: string }) => {
    setIsSubmitting(true);
    setIsErrorRateLimit(false);
    mutate(values, {
      onSuccess: async (data: {
        authToken: string;
        refreshToken: string;
        organizations: TVendorCompanies;
      }) => {
        // init indexedDB data
        init()
          .then(() => {
            let eligibleToAccess = 'false';
            let userRoleId = '';

            if (
              data?.organizations?.admin?.id &&
              (data?.organizations?.customers?.length ?? 0) === 0
            ) {
              eligibleToAccess = 'true';
              userRoleId = data?.organizations?.admin?.userRoleId;
            }

            setAuthentication(
              {
                authToken: data?.authToken,
                refreshToken: data?.refreshToken,
                userInitialed: 'false',
                eligibleToAccess: eligibleToAccess,
                userRoleId: userRoleId,
              },
              config?.DOMAIN_URL
            );

            setValues({
              admin: data?.organizations?.admin,
              customers: data?.organizations?.customers,
            });

            setTimeout(() => {
              if (
                eligibleToAccess === 'false' &&
                (data?.organizations?.customers?.length ?? 0) > 0
              ) {
                return navigate('/vendor/company-selection');
              }
              if (type === 'newUser') {
                return (window.location.href = `${config.MOBI_URL}?type=newUser`);
              } else {
                return (window.location.href = `${config.MOBI_URL}`);
              }
            }, 600);
          })
          .catch(() => {
            Toaster.negative(t('warning_login_db_failed'));
          });
      },
      onError: (error) => {
        const errorData = error as {
          response: { data: { message: string; errorCode: string } };
        };

        if (errorData?.response?.data?.message === 'ERR_USER_UNVERIFIED') {
          setShowErrorVerification(true);
        } else {
          setShowErrorVerification(false);
          setIsErrorAuthenticated(true);

          if (
            errorData?.response?.data?.errorCode === 'ERR_LOGIN_LIMIT_REACHED'
          ) {
            setIsErrorRateLimit(true);
          }
        }
      },
    });
  };

  const handShowPassword = () => setIsShowPassword(!isShowPassword);

  return {
    isSubmitting,
    isErrorAuthenticated,
    onSubmitLogin,
    setIsErrorAuthenticated,
    isLoading,
    isShowPassword,
    handShowPassword,
    showErrorVerification,
    isErrorRateLimit,
  };
};

export default useLogin;
