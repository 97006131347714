import { Meta } from '@navi-app/ui';
import { withErrorBoundary } from '@navi-app/utils';
import { Button } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { StyledLink } from 'baseui/link';
import { PhoneInput } from 'baseui/phone-input';
import {
  HeadingLarge,
  LabelLarge,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { lazy, Suspense, useState } from 'react';
import config from '../../commons/constants/config';
import { getCountryByDialCode } from '../../commons/helper/country-code';
import useRegister from './hooks/use-register';

const Footer = lazy(() => import('../../commons/components/footer'));
const FieldErrorMessage = lazy(
  () => import('./components/field-error-message')
);
const FieldPassword = lazy(() => import('./components/field-password'));

function Regiter() {
  const {
    t,
    handShowPassword,
    handleSubmit,
    isShowPassword,
    handShowPasswordConfirmation,
    isShowPasswordConfirmation,
    errors,
    touched,
    values,
    handleChange,
    changeValue,
    isLoading,
  } = useRegister();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <>
      <Meta
        key="meta-register"
        title={t('meta.title')}
        description={t('meta.description')}
        config={config}
      />
      <Suspense>
        <div className="flex flex-col items-center justify-between lg:justify-center min-h-screen px-4 pt-[44px] lg:pt-0 pb-[30px] lg:pb-0 mt-10">
          <div className="px-4 py-5 lg:p-10 border border-solid borde-[#E8E8E8] rounded-lg w-full lg:w-[493px] mb-6">
            <img
              src="https://assets.mobitech.id/images/mobitech-logo.webp"
              alt="navi-logo"
              height={58}
              width={206}
            />

            <HeadingLarge marginTop="40px" marginBottom="24px">
              {t('title')}
            </HeadingLarge>

            <form onSubmit={handleSubmit}>
              {/* Company Name */}
              <FormControl
                label={
                  <LabelLarge>
                    {t('form.company.name')}
                    <span className="text-red-500 text-base -mt-1">*</span>
                  </LabelLarge>
                }
                overrides={{
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      marginBottom: '24px',
                    }),
                  },
                }}
                error={
                  touched?.companyName && errors?.companyName ? (
                    <FieldErrorMessage errorMessage={errors?.companyName} />
                  ) : null
                }
              >
                <Input
                  id="companyName"
                  name="companyName"
                  type="text"
                  placeholder={t('form.company.name_placeholder')}
                  onChange={handleChange}
                  value={values?.companyName}
                  error={touched?.companyName && errors?.companyName}
                />
              </FormControl>
              {/* User Name */}
              <FormControl
                label={
                  <LabelLarge>
                    {t('form.name')}
                    <span className="text-red-500 text-base -mt-1">*</span>
                  </LabelLarge>
                }
                overrides={{
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      marginBottom: '24px',
                    }),
                  },
                }}
                error={
                  touched?.name && errors?.name ? (
                    <FieldErrorMessage errorMessage={errors?.name} />
                  ) : null
                }
              >
                <Input
                  id="name"
                  name="name"
                  type="text"
                  placeholder={t('form.name_placeholder')}
                  onChange={handleChange}
                  value={values?.name}
                  error={touched?.name && errors?.name}
                />
              </FormControl>

              {/* User Phone Number */}
              <FormControl
                label={
                  <LabelLarge>
                    {t('form.phone_number')}
                    <span className="text-red-500 text-base -mt-1">*</span>
                  </LabelLarge>
                }
                overrides={{
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      marginBottom: '24px',
                    }),
                  },
                }}
                error={
                  touched?.phoneNumber && errors?.phoneNumber ? (
                    <FieldErrorMessage errorMessage={errors?.phoneNumber} />
                  ) : null
                }
              >
                <PhoneInput
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder={t('form.phone_number_placeholder')}
                  text={values?.phoneNumber}
                  country={getCountryByDialCode(values?.countryCode)}
                  onTextChange={(e) => {
                    if (/^[0-9]*$/.test(e.target.value)) {
                      changeValue('phoneNumber', e.target.value);
                    }
                  }}
                  onCountryChange={({ option }) => {
                    changeValue('countryCode', option?.dialCode);
                  }}
                  error={touched?.phoneNumber && errors?.phoneNumber}
                />
              </FormControl>

              {/* User Email */}
              <FormControl
                label={
                  <LabelLarge>
                    {t('form.email')}
                    <span className="text-red-500 text-base -mt-1">*</span>
                  </LabelLarge>
                }
                overrides={{
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      marginBottom: '24px',
                    }),
                  },
                }}
                error={
                  touched?.email && errors?.email ? (
                    <FieldErrorMessage errorMessage={errors?.email} />
                  ) : null
                }
              >
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t('form.email_placeholder')}
                  onChange={handleChange}
                  value={values?.email}
                  error={touched?.email && errors?.email}
                />
              </FormControl>

              {/* User Password */}
              <FieldPassword
                title={t('form.password')}
                placeholder={t('form.password_placeholder')}
                handShowPassword={handShowPassword}
                isShowPassword={isShowPassword}
                name="password"
                isTouched={(touched?.password || false) as boolean}
                errorMessage={errors?.password}
                value={values?.password}
                handleChange={handleChange}
              />

              {/* User Password Confirmation */}
              <FieldPassword
                title={t('form.password_confirmation')}
                placeholder={t('form.password_confirmation_placholder')}
                handShowPassword={handShowPasswordConfirmation}
                isShowPassword={isShowPasswordConfirmation}
                name="passwordConfirmation"
                isTouched={(touched?.passwordConfirmation || false) as boolean}
                errorMessage={errors?.passwordConfirmation}
                value={values?.passwordConfirmation}
                handleChange={handleChange}
              />

              <div className="flex flex-row items-start gap-4">
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <ParagraphSmall>
                  Dengan mendaftar, saya menyetujui
                  {/* <StyledLink
                    className="!no-underline text-[#1E64DC] cursor-pointer mx-1"
                    href="https://assets.mobitech.id/docs/mobi-saas-agreement.pdf"
                    target="_blank"
                  >
                    Perjanjian Perangkat Lunak
                  </StyledLink>
                  dan */}
                  <StyledLink
                    className="!no-underline text-[#1E64DC] cursor-pointer mx-1"
                    href="https://assets.mobitech.id/docs/mobi-terms-conditions.pdf"
                    target="_blank"
                  >
                    Syarat & Ketentuan
                  </StyledLink>
                  Mobitech.
                </ParagraphSmall>
              </div>

              <Button
                className="!w-full !text-base lg:!text-lg !mt-6"
                type="submit"
                isLoading={isLoading}
                disabled={!isChecked}
              >
                {t('title')}
              </Button>
            </form>

            <div className="flex mt-6 lg:mt-8 items-center">
              <ParagraphMedium>
                {t('already_have_account')}?
                <StyledLink
                  className="!no-underline text-[#1E64DC] cursor-pointer ml-1"
                  href="/auth"
                >
                  {t('login')}
                </StyledLink>
              </ParagraphMedium>
            </div>
          </div>
          <Footer isUnFixed />
        </div>
      </Suspense>
    </>
  );
}

export default withErrorBoundary(Regiter, { isPage: true });
