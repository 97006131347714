import { apiAuthentication, removeAuthentication } from '@navi-app/utils';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import config from '../../../commons/constants/config';
import companySelectionStore from '../store/company-selection.store';

const useCompanySelection = () => {
  const { admin, customers, resetValues } = companySelectionStore();

  const apiAuth = apiAuthentication.authenticationAPI(config.API_URL || '');
  const mutationLogout = useMutation(apiAuth.logout);

  const onChooseCompany = (userRoleId: string, customerId: string) => {
    Cookies.set('userRoleId', userRoleId, {
      domain: config?.DOMAIN_URL,
    });
    Cookies.set('customerId', customerId, {
      domain: config?.DOMAIN_URL,
    });
    Cookies.set('eligibleToAccess', 'true', {
      domain: config?.DOMAIN_URL,
    });

    const isEligibleToAccess = Cookies.get('eligibleToAccess') === 'true';
    const isUserRoleIdSet = Cookies.get('userRoleId') === userRoleId;
    const isCustomerIdSet = Cookies.get('customerId') === customerId;

    if (isEligibleToAccess && isUserRoleIdSet && isCustomerIdSet) {
      window.location.href = `${config.MOBI_URL}`;
    }
  };

  const logout = () => {
    removeAuthentication(config.AUTH_URL);
    resetValues();
    setTimeout(() => {
      window.location.href = '/auth';
      resetValues();
    }, 600);
  };

  const onLogout = () => {
    mutationLogout.mutate(null, {
      onSuccess: logout,
      onError: logout,
    });
  };

  useEffect(() => {
    const authToken = Cookies.get('authToken');
    if (!authToken) {
      window.location.href = '/auth';
    }
  }, []);

  return {
    onChooseCompany,
    onLogout,
    admin,
    customers,
  };
};

export default useCompanySelection;
