import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { KeyIcon } from '@heroicons/react/24/solid';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ROLE, SIZE } from 'baseui/modal';
import { HeadingMedium, LabelLarge, ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import useForgotPassword from '../hooks/use-forgot-password';

export default function ModalForgotPassword() {
  const { t } = useTranslation('auth');
  const {
    closeModal,
    errors,
    handleChange,
    isOpen,
    onSubmit,
    touched,
    values,
    isDisabled,
    isLoading,
    validateEmail,
  } = useForgotPassword({ t });

  return (
    <Modal
      onClose={closeModal}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Close: {
          style: ({ $theme }) => ({
            marginRight: '8px',
            marginTop: '8px',
          }),
        },
      }}
    >
      <ModalBody>
        <div className="flex flex-col items-center mt-[92px]">
          <div className="p-[18px] bg-[#F4F6F8] rounded-full">
            <KeyIcon className="text-[#03222F] w-[47px] h-[47px]" />
          </div>
          <HeadingMedium className="!mt-4 text-center">
            {t('forgot_password')}
          </HeadingMedium>
          <ParagraphMedium className="!mt-2 text-center">
            {t('forgot_password_message')}
          </ParagraphMedium>
        </div>
        <form className="mt-10" onSubmit={onSubmit}>
          <FormControl
            label={<LabelLarge>{t('email_name')}</LabelLarge>}
            error={
              (touched?.email && errors?.email) || !validateEmail.isValid ? (
                <div className="flex items-center">
                  <InformationCircleIcon className="w-[14px] h-[14px] mr-1" />
                  <span>{errors?.email || validateEmail.message}</span>
                </div>
              ) : null
            }
          >
            <Input
              id="email"
              name="email"
              type="email"
              placeholder={t('email.placeholder')}
              value={values?.email}
              onChange={handleChange}
              error={
                (touched?.email && errors?.email) || !validateEmail.isValid
              }
            />
          </FormControl>

          <Button
            className="!w-full !text-base lg:!text-lg !mt-6"
            type="submit"
            disabled={isDisabled}
            isLoading={isLoading}
          >
            {t('change_password')}
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
}
